import React from 'react';
import { Stepper, Step, StepLabel, Typography, Box, Button } from '@mui/material';

const steps = [
  'Initial Consultation',
  'Property Valuation',
  'Preparation for Listing',
  'Tenant Screening',
  'Lease Agreement',
  'Property Management',
  'Periodic Reviews',
  'End of Tenancy'
];

const stepDescriptions = [
  'We will meet with you to understand your needs, rental goals and possible pre-work.',
  'Assessment of the property to determine a suitable rental price.',
  'Photography/Videography of property in order to create marketing materials and arrange viewings.',
  'Conduct background checks and references to find a suitable tenant.',
  'Draft and negotiate the lease agreement(s).',
  'Handle ongoing property management tasks, including rent collection and maintenance.',
  'Provide regular updates and review the property’s performance.',
  'Manage the end-of-tenancy process, including inspections and preparation for next tenant.'
];

function EstateAgentSteps() {
  const [activeStep, setActiveStep] = React.useState(0);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        maxWidth: '800px',
        margin: '0 auto',
        padding: { xs: 1, sm: 2, md: 3 }
      }}
    >
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        sx={{
          width: '100%',
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: 'center'
        }}
      >
        {steps.map((label, index) => (
          <Step key={index}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ mt: 2, textAlign: 'center' }}>
        <Typography variant="h6">{steps[activeStep]}</Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          {stepDescriptions[activeStep]}
        </Typography>
      </Box>
      <Box
        sx={{
          mt: 2,
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          gap: 2,
          width: '100%',
          justifyContent: 'center'
        }}
      >
        <Button
          disabled={activeStep === 0}
          onClick={() => setActiveStep((prev) => prev - 1)}
          sx={{
            backgroundColor: "#0c121b",
            color: "#FFFFFF",
            "&:hover": {
              backgroundColor: "#2d394b",
            },
            flex: 1
          }}
        >
          Back
        </Button>
        <Button
          disabled={activeStep === steps.length - 1}
          onClick={() => setActiveStep((prev) => prev + 1)}
          sx={{
            backgroundColor: "#0c121b",
            color: "#FFFFFF",
            "&:hover": {
              backgroundColor: "#2d394b",
            },
            flex: 1
          }}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
}

export default EstateAgentSteps;
