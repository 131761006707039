import React from 'react';
import { Box } from '@mui/material';
import discoverImg from '../../assets/images/discover-img.jpeg';
import ManageServiceCardChild from './ManageServiceCardChild';

function ManageServiceCard() {
  return (
    <Box sx={{
        height: '75vh',
        backgroundImage: `url(${discoverImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex'
    }}>
        <ManageServiceCardChild variant='h5' name='Management services' description='Your trusted partner in property maintenance, management, and staging. We specialize in maximizing the value and appeal of your property, ensuring it remains in top condition while attracting the right buyers or tenants.'/>
    </Box>
  )
}

export default ManageServiceCard;