import React from "react";
import Footer from "../../components/Footer";
import Tabs from "./PricingTabs";
import { Box, Container, Typography, Divider } from "@mui/material";
import FadeInUp from "../../components/FadeInUp";
import { Helmet } from "react-helmet-async";
import ManageServiceCardChild from '../../components/MaintenancePageComponents/ManageServiceCardChild';
import discoverImg from '../../assets/images/fix.jpg';


function PricingPage({ title }) {
  return (
    <div>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Box>
      <Box sx={{
        height: '75vh',
        backgroundImage: `url(${discoverImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex'
    }}>
        <ManageServiceCardChild variant='h5' name='Maintenance' description='For when things go wrong, our team are here to assist and repair.'/>
    </Box>
        <Container
          sx={{
            paddingY: "3rem",
          }}
        >

          <Typography
            variant="h4"
            sx={{
              textAlign: "center",
              textTransform: "uppercase",
              marginBottom: "1rem",
            }}
          >
            Choose the right plan
          </Typography>
          <Divider></Divider>
          <Typography             sx={{
              textAlign: "center",
              marginBottom: "1rem",
            }}>
            Discover the differences between our tenant find or our fully managed service for your property.
          </Typography>
          <Tabs></Tabs>
        </Container>
      </Box>
      <Footer></Footer>
    </div>
  );
}

export default PricingPage;
