import React, { useState,useEffect } from "react";
import {
  Button,
  Box,
  Fade,
  Badge
} from "@mui/material";
import PropertyUpload from "./propertyUpload";
import AdminProperties from "./adminProperties";
import TicketList from "./TicketList";

const PricingTabs = () => {
  const [activeTab, setActiveTab] = useState("current");
  const [fadeKey, setFadeKey] = useState(0); // Key for fade effect

  const [tickets, setTickets] = useState([]);
  const [openTicketsCount, setOpenTicketsCount] = useState(0);
  const apiUrl = process.env.REACT_APP_API_URL;

  const fetchTickets = async () => {
    try {
      const token = localStorage.getItem("jwtToken");

      const response = await fetch(`${apiUrl}/alltickets`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setTickets(data); // Store all tickets in state

      // Count open tickets
      const openTicketsCount = data.filter(ticket => ticket.Status === "Open").length;
      setOpenTicketsCount(openTicketsCount); // Set the open tickets count
    } catch (error) {
      console.error("Error fetching tickets:", error);
    }
  };

  useEffect(() => {
    fetchTickets();
  }, []); // Empty dependency array to run only once on mount

  const handleOpenTicketsCountChange = (count) => {
    setOpenTicketsCount(count);
  };

  // Variables for badge content
  const enquiryCount = 5; // Example number for enquiries

  const renderPricing = () => {
    if (activeTab === "current") {
      return (
        <Box>
          <AdminProperties />           
        </Box>
      );
    } else if (activeTab === "listings") {
      return (
        <Box>
          <PropertyUpload />           
        </Box>
      );
    } else if (activeTab === "contact") {
      return (
        <Box>
          <PropertyUpload />           
        </Box>
      );
    } else if (activeTab === "tickets") {
      return (
        <Box>
          <TicketList ticketsAPI={tickets} onOpenTicketsCountChange={handleOpenTicketsCountChange} />
        </Box>
      );
    }
  };

  const handleTabChange = (tab) => {
    setFadeKey((prevKey) => prevKey + 1); // Change key to trigger fade
    setActiveTab(tab);
  };

  return (
    <Box sx={{ textAlign: "center", padding: 2 }}>
      <Box sx={{ display: "flex", justifyContent: "center", marginBottom: 2 }}>
        <Button
          variant="contained"
          onClick={() => handleTabChange("current")}
          sx={{
            marginRight: "5px",
            backgroundColor: activeTab === "current" ? "#0c121b" : "transparent",
            color: activeTab === "current" ? "#FFFFFF" : "#000000",
            "&:hover": {
              backgroundColor: activeTab === "current" ? "#2d394b" : "#e0e0e0",
            },
            border: activeTab !== "current" ? "1px solid rgba(0, 0, 0, 0.23)" : "none",
          }}
        >
          Current Listings
        </Button>

        <Button
          variant="contained"
          onClick={() => handleTabChange("listings")}
          sx={{
            marginRight: "5px",
            backgroundColor: activeTab === "listings" ? "#0c121b" : "transparent",
            color: activeTab === "listings" ? "#FFFFFF" : "#000000",
            "&:hover": {
              backgroundColor: activeTab === "listings" ? "#2d394b" : "#e0e0e0",
            },
            border: activeTab !== "listings" ? "1px solid rgba(0, 0, 0, 0.23)" : "none",
          }}
        >
          Create a Listing
        </Button>

        {openTicketsCount > 0 ? (
          <Badge
            badgeContent={openTicketsCount}
            color="error"
            overlap="circular"
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Button
              variant="contained"
              onClick={() => handleTabChange("tickets")}
              sx={{
                marginRight: "5px",
                backgroundColor: activeTab === "tickets" ? "#0c121b" : "transparent",
                color: activeTab === "tickets" ? "#FFFFFF" : "#000000",
                "&:hover": {
                  backgroundColor: activeTab === "tickets" ? "#2d394b" : "#e0e0e0",
                },
                border: activeTab !== "tickets" ? "1px solid rgba(0, 0, 0, 0.23)" : "none",
              }}
            >
              Tickets
            </Button>
          </Badge>
        ) : (
          <Button
            variant="contained"
            onClick={() => handleTabChange("tickets")}
            sx={{
              marginRight: "5px",
              backgroundColor: activeTab === "tickets" ? "#0c121b" : "transparent",
              color: activeTab === "tickets" ? "#FFFFFF" : "#000000",
              "&:hover": {
                backgroundColor: activeTab === "tickets" ? "#2d394b" : "#e0e0e0",
              },
              border: activeTab !== "tickets" ? "1px solid rgba(0, 0, 0, 0.23)" : "none",
            }}
          >
            Tickets
          </Button>
        )}

        {enquiryCount > 0 ? (
          <Badge
            badgeContent={enquiryCount}
            color="error"
            overlap="circular"
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Button
              variant="contained"
              onClick={() => handleTabChange("contact")}
              sx={{
                marginRight: "5px",
                backgroundColor: activeTab === "contact" ? "#0c121b" : "transparent",
                color: activeTab === "contact" ? "#FFFFFF" : "#000000",
                "&:hover": {
                  backgroundColor: activeTab === "contact" ? "#2d394b" : "#e0e0e0",
                },
                border: activeTab !== "contact" ? "1px solid rgba(0, 0, 0, 0.23)" : "none",
              }}
            >
              Enquiries
            </Button>
          </Badge>
        ) : (
          <Button
            variant="contained"
            onClick={() => handleTabChange("contact")}
            sx={{
              marginRight: "5px",
              backgroundColor: activeTab === "contact" ? "#0c121b" : "transparent",
              color: activeTab === "contact" ? "#FFFFFF" : "#000000",
              "&:hover": {
                backgroundColor: activeTab === "contact" ? "#2d394b" : "#e0e0e0",
              },
              border: activeTab !== "contact" ? "1px solid rgba(0, 0, 0, 0.23)" : "none",
            }}
          >
            Enquiries
          </Button>
        )}
      </Box>

      <Fade in key={fadeKey} timeout={1000}>
        <Box>{renderPricing()}</Box>
      </Fade>
    </Box>
  );
};

export default PricingTabs;
