import React from 'react';
import { useInView } from 'react-intersection-observer';
import '../styles.css'; // Ensure this CSS file is correctly imported

const FadeInUp = ({ children }) => {
  const { ref, inView } = useInView({ triggerOnce: true });

  return (
    <div
      ref={ref}
      className={`fade-in-up ${inView ? 'visible' : ''}`}
    >
      {children}
    </div>
  );
};

export default FadeInUp;
