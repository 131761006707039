import React, { useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import BuildIcon from "@mui/icons-material/Build";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import "../App.css";
import { HourglassEmpty } from "@mui/icons-material";

const FetchTicket = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [showModal, setShowModal] = useState(false);
  const [ticketNumber, setTicketNumber] = useState("");
  const [email, setEmail] = useState("");
  const [ticketData, setTicketData] = useState(null);
  const [error, setError] = useState(null);
  const [newComment, setNewComment] = useState(""); // New state for comment input
  const [submitting, setSubmitting] = useState(false); // Loading state

  const handleFetchTicket = async () => {
    setTicketData(null);
    setError(null);

    try {
      const response = await axios.get(
        `${apiUrl}/ticket?ticketID=${ticketNumber}&email=${encodeURIComponent(
          email
        )}`
      );
      if (response.data.error) {
        setError(response.data.error);
      } else {
        setTicketData(response.data);
      }
    } catch (error) {
      setError("No ticket found with details provided.");
    }
  };

  const handleSubmitComment = async () => {
    if (!newComment.trim()) return; // Don't submit if comment is empty

    setSubmitting(true);
    try {
      const formData = new FormData();
      formData.append('ticketID', ticketNumber);
      formData.append('commentText', newComment);
      formData.append('commentType', 'Customer');

      const response = await axios.post(`${apiUrl}/ticketcomment`, formData, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
      if (response.status === 200) {
        // Update ticketData with the new comment
        setTicketData((prevData) => ({
          ...prevData,
          Status: "Awaiting Response",
          Comments: [
            ...prevData.Comments,
            {
              CommentID: response.data.CommentID, // Assuming the API returns a new comment ID
              CommentText: newComment,
              CommentType: "Customer", // You can specify the type based on your system
              CommentedAt: new Date().toISOString()
            },
          ],
        }));
        setNewComment(""); // Clear the comment input
      }
    } catch (error) {
      console.error("Error submitting comment:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setTicketData(null);
    setError(null);
    setTicketNumber("");
    setEmail("");
  };

  return (
    <div>
      <Button
        onClick={() => setShowModal(true)}
        variant="contained"
        sx={{
          backgroundColor: "#0c121b",
          color: "#FFFFFF",
          "&:hover": {
            backgroundColor: "#2d394b",
          },
        }}
      >
        Fetch Request
      </Button>

      <div className={`modal-overlay ${showModal ? "show" : ""}`}>
        <div className={`modal ${showModal ? "show" : ""}`}>
          {ticketData ? (
            <>
              <div className="ticket-details">
                <h2>Maintenance Request Details</h2>
                <div className="details-grid">
                  <p>
                    <strong>Name:</strong> {ticketData.Name}
                  </p>
                  <p>
                    <strong>Phone:</strong> {ticketData.Phone}
                  </p>
                  <p>
                    <strong>Email:</strong> {ticketData.Email}
                  </p>
                  <p>
                    <strong>Status: </strong>
                    <span
                      style={{
                        color:
                          ticketData.Status === "Open"
                            ? "red"
                            : ticketData.Status === "Completed"
                            ? "green"
                            : ticketData.Status === "In Progress"
                            ? "blue"
                            : "orange",
                      }}
                    >
                      {ticketData.Status === "In Progress" && (
                        <BuildIcon fontSize="small" />
                      )}
                      {ticketData.Status === "Open" && (
                        <FiberNewIcon fontSize="small" />
                      )}
                      {ticketData.Status === "Completed" && (
                        <AssignmentTurnedInIcon fontSize="small" />
                      )}
                      {ticketData.Status === "Awaiting Response" && (
                        <HourglassEmpty fontSize="small" />
                      )}
                      {ticketData.Status}
                    </span>
                  </p>
                  <p>
                    <strong>Date:</strong> {ticketData.CreatedAt}
                  </p>
                </div>
                <p>
                  <strong>Issue:</strong> {ticketData.Issue}
                </p>
              </div>

              <div className="comments">
                <h4>Comments</h4>
                {ticketData.Comments.map((comment) => (
                  <div
                    key={comment.CommentID}
                    className={`comment ${comment.CommentType}`}
                  >
                    <p>{comment.CommentText}</p>
                    <span className="comment-time">
                      {comment.CommentType + " at: "}
                      {new Date(comment.CommentedAt).toLocaleString()}
                    </span>
                  </div>
                ))}

                {/* New Comment Form */}
                <div className="comment-form" style={{marginLeft: "5px", width: "90%"}}>
                  <textarea
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                    placeholder="Leave a comment..."
                    rows={2}
                    disabled={submitting}
                  />
                  <Button
                    onClick={handleSubmitComment}
                    variant="contained"
                    sx={{
                      backgroundColor: "#0c121b",
                      color: "#FFFFFF",
                      "&:hover": {
                        backgroundColor: "#2d394b",
                      },
                      marginTop: "10px",
                    }}
                    disabled={submitting}
                  >
                    {submitting ? "Submitting..." : "Submit Comment"}
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="field-group">
                <label htmlFor="ticketNumber">Request Number</label>
                <input
                  id="ticketNumber"
                  type="text"
                  placeholder="Enter ticket number"
                  value={ticketNumber}
                  onChange={(e) => setTicketNumber(e.target.value)}
                />
              </div>
              <div className="field-group">
                <label htmlFor="email">Email</label>
                <input
                  id="email"
                  type="email"
                  placeholder="Enter email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </>
          )}

          <div className="button-container">
            {!ticketData && (
              <Button
                onClick={handleFetchTicket}
                variant="contained"
                sx={{
                  backgroundColor: "#0c121b",
                  color: "#FFFFFF",
                  "&:hover": {
                    backgroundColor: "#2d394b",
                  },
                }}
              >
                Fetch
              </Button>
            )}
            <Button
              onClick={handleCloseModal}
              variant="contained"
              sx={{
                backgroundColor: "#0c121b",
                color: "#FFFFFF",
                "&:hover": {
                  backgroundColor: "#2d394b",
                },
                marginTop: "20px",
              }}
            >
              Close
            </Button>
          </div>

          {error && <p style={{ color: "red" }}>{error}</p>}
        </div>
      </div>
    </div>
  );
};

export default FetchTicket;
