import React from 'react';
import { Box } from '@mui/material';
import DiscoverCard from './DiscoverCard';

function SecondHeaderItem(props) {

  return (
        <Box sx={{
            height: '75vh',
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),url(${props.secondHeaderImg})`,
            backgroundSize: 'cover', 
            backgroundPosition: `${props.imgPosition}`, 
            backgroundAttachment: { xs: 'unset', md: 'fixed' },
            display: 'flex',
            flexDirection: `${props.direction}`
        }}>  
            <DiscoverCard name={props.secondHeaderTitle} variant='h1' displayType='none' direction={props.direction} />
            
        </Box>
  )
}

export default SecondHeaderItem;