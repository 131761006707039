import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import RentItem from "./Listings/PropertyList";
import {
  Container,
  Pagination,
  Typography,
  Alert,
  Snackbar,
  Box,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import FadeInUp from "../FadeInUp";
import "../Misc/SplashPage.css";
import { Helmet } from "react-helmet-async";
import { SentimentDissatisfied } from "@mui/icons-material";
import SplashPage from "../Misc/APISplash";

function Rent({ title }) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const topOfListRef = useRef(null);

  const [rentItems, setRentItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [alertAddress, setAddressBanner] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const [searchQuery, setSearchQuery] = useState("");
  const [statusFilter, setStatusFilter] = useState("");

  // Handle the search query change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Handle status filter change
  const handleStatusChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const filteredItems = rentItems
    .filter(
      (item) =>
        item.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.address.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.town.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .filter((item) => (statusFilter ? item.status === statusFilter : true));

  // Fetch the items when the component mounts
  useEffect(() => {
    fetchItems();
  }, []);

  const fetchItems = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/properties`);
      setRentItems(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const [open3, setOpen3] = useState(false);

  const handleAlertOpen = () => {
    setOpen3(true);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen3(false);
  };

  const handleDeleteItem = async (urluuid, address) => {
    try {
      const response = await fetch(`${apiUrl}/deleteproperty`, {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: urluuid }),
      });

      if (response.ok) {
        handleAlertOpen();
        // Update the state directly without refetching all items
        setRentItems((prevItems) =>
          prevItems.filter((item) => item.urluuid !== urluuid)
        );
        setAddressBanner(address + " has been deleted successfully.");
      } else {
        console.error("Error deleting property with urluuid:", urluuid);
      }
    } catch (error) {
      console.error("Error deleting:", error);
    }
  };

  const handleEditItem = async (formData) => {
    try {
      const response = await fetch(`${apiUrl}/updateproperty`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        handleAlertOpen();
        setAddressBanner(formData.address + " has been edited successfully.");
  
        // Update the specific edited item in the rentItems state
        setRentItems((prevItems) =>
          prevItems.map((item) =>
            item.urluuid === formData.urluuid ? { ...item, ...formData } : item
          )
        );
      } else {
        console.error("Error editing property with urluuid:", formData.urluuid);
      }
    } catch (error) {
      console.error("Error editing property:", error);
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    if (topOfListRef.current) {
      topOfListRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  if (loading) {
    return (
      <div>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <SplashPage />
      </div>
    );
  }

  return (
    <div ref={topOfListRef}>
      <Typography variant="h5" sx={{ marginTop: 2 }}>
        Current Listings
      </Typography>

      <Container sx={{ textAlign: "center" }}>
        <Box sx={{ display: "flex", gap: 2, marginBottom: 2, marginTop: 2 }}>
          <TextField
            label="Search by title or address"
            variant="outlined"
            fullWidth
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              value={statusFilter}
              label="Status"
              onChange={handleStatusChange}
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="Agreed">Agreed</MenuItem>
              <MenuItem value="To Let">To Let</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <FadeInUp>
          {currentItems.length === 0 ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: 4,
              }}
            >
              <SentimentDissatisfied sx={{ fontSize: 40, color: "grey.500" }} />
              <Typography
                variant="h6"
                sx={{ fontWeight: "normal", marginTop: 2 }}
              >
                Well this is awkward... we currently don't have any properties
                available, check back later for more!
              </Typography>
            </Box>
          ) : (
            currentItems.map((item) => (
              <RentItem
                key={item.id}
                id={item.id}
                title={item.title}
                address={item.address}
                price={item.price}
                shortDescription={item.longDescription}
                bedrooms={item.bedrooms}
                bathrooms={item.bathrooms}
                livingrooms={item.livingrooms}
                img={item.image1}
                urluuid={item.urluuid}
                mapCoords={item.mapCoords}
                distance={item.distance}
                dateAdded={item.dateAdded}
                postcode={item.postcode}
                town={item.town}
                county={item.county}
                viewableFrom={item.viewableFrom}
                views={item.views}
                status={item.status}
                deposit={item.deposit}
                style={item.style}
                energyRating={item.energyRating}
                heatingType={item.heatingType}
                leaseLength={item.leaseLength}
                furnishing={item.furnishing}
                handleDeleteItem={handleDeleteItem} // Pass delete function
                handleEditItem={handleEditItem} // Pass delete function
              />
            ))
          )}
        </FadeInUp>
      </Container>

      <Stack
        spacing={2}
        sx={{ margin: 4, justifyContent: "center", alignItems: "center" }}
      >
        <Pagination
          count={Math.ceil(rentItems.length / itemsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          sx={{
            "& .MuiPaginationItem-root": {
              color: "#0c121b", // Color for unselected pagination items
            },
            "& .MuiPaginationItem-root.Mui-selected": {
              backgroundColor: "#0c121b", // Background color for selected pagination item
              color: "#FFFFFF", // Text color for selected pagination item
            },
            "& .MuiPaginationItem-root:hover": {
              backgroundColor: "#2d394b", // Hover background color for pagination items
              color: "#FFFFFF", // Hover text color for pagination items
            },
          }}
        />
      </Stack>

      <Snackbar open={open3} autoHideDuration={6000} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="success">
          {alertAddress}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Rent;
