import React from "react";
import discoverImg from '../../assets/images/let.jpg';
import { Box, Container, Divider, Typography } from "@mui/material";
import ManageServiceCardChild from '../../components/MaintenancePageComponents/ManageServiceCardChild';
import { Helmet } from 'react-helmet-async';
import {
  AutoAwesome,
  Handyman,
  SupportAgent,
  Loyalty,
  ManageHistory,
  PublishedWithChanges,
  CameraRoll,
} from "@mui/icons-material";
import Footer from "../../components/Footer";
import FadeInUp from "../../components/FadeInUp";
import ImageGallerySlider from "../../components/ImageGallerySlider";
import img1 from "../../assets/images/26-Silverstream-Gardens_2.jpg";
import img2 from "../../assets/images/26-Silverstream-Gardens_6.jpg";


const images = [
  {
    original: img1,
    thumbnail: img1,
  },
  {
    original: img2,
    thumbnail: img2,
  },
];

function Management({ title }) {
  return (
    <div>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Box sx={{
        height: '75vh',
        backgroundImage: `url(${discoverImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex'
    }}>









        <ManageServiceCardChild variant='h5' name='Marketing' description="Ensuring your property is advertised to it's fullest potential to maximise value."/>
    </Box>
    <Container>
        <FadeInUp>
          <Typography
            variant="h2"
            sx={{
              fontSize: "clamp(1.8rem, 1.3654rem + 0.9065vw, 2rem)",
              textTransform: "uppercase",
              marginBottom: "0.25rem",
              marginTop: "1.00rem",
              textAlign: "center",
            }}
          >
            Marketing
          </Typography>
          <Divider></Divider>
          <Box display="flex" alignItems="center" sx={{marginTop: "0.5rem"}}>
            <AutoAwesome sx={{ marginRight: "0.5rem" }} />{" "}
            <Typography
              variant="h5"
              sx={{
                textTransform: "uppercase",
                marginBottom: "0.25rem",
                marginTop: "0.5rem",
                textAlign: "left",
              }}
            >
              Why marketing?
            </Typography>
          </Box>
          <Typography>
            Property marketing plays a vital role in attracting potential buyers
            or tenants. It goes beyond listing the property; it's about
            strategically presenting it to emphasize its unique characteristics.
            This involves professional photography, crafting compelling
            descriptions, and ensuring the property is clean and visually
            appealing. Effective marketing can lead to increased interest,
            quicker transactions, and often, better rental or sale prices,
            making it an essential part of the property management process.
          </Typography>
          <Box display="flex" alignItems="center" sx={{marginTop: "0.5rem"}}>
            <CameraRoll sx={{ marginRight: "0.5rem" }} />{" "}
            <Typography
              variant="h5"
              sx={{
                textTransform: "uppercase",
                marginBottom: "0.25rem",
                marginTop: "0.25rem",
                textAlign: "left",
              }}
            >
              Videography/Photography Packages
            </Typography>
          </Box>
          <Typography>
            We offer packages of professional videography and photography
            which help boost property leases by showcasing the
            property in high quality, attracting more interest, and enhancing
            online visibility. They help potential renters envision
            themselves in the space, improve the property's perceived value, and
            provide a competitive edge in a crowded market.
          </Typography>
          <div></div>
          <Typography
            sx={{
              marginBottom: "0.5rem",
              marginTop: "0.5rem",
              textAlign: "left",
            }}
          >
            Just after photography and videography on its own? We can also arrange this
            too.
          </Typography>
          <Box display="flex" alignItems="center" sx={{marginTop: "0.5rem"}}>
            <Loyalty sx={{ marginRight: "0.5rem" }} />{" "}
            <Typography
              variant="h5"
              sx={{
                textTransform: "uppercase",
                marginBottom: "0.25rem",
                marginTop: "0.25rem",
                textAlign: "left",
              }}
            >
              Did you know?
            </Typography>
          </Box>
          <Typography>
          Professional real estate images attract more views, with listings that include high-resolution or twilight photos receiving up to three times more showings than those without. In fact, 99% of buyers use online platforms for home searches, with 87% of them citing listing images as a key factor in their decisions​
          </Typography>
          {/** 
          <Typography
            variant="h2"
            sx={{
              fontSize: "clamp(1.8rem, 1.3654rem + 0.9065vw, 2rem)",
              textTransform: "uppercase",
              marginBottom: "1rem",
              marginTop: "1rem",
              textAlign: "center",
            }}
          >
            Our Sample Work
          </Typography>
          <Divider sx= {{ marginBottom: "1rem"}}></Divider>
          <ImageGallerySlider images={images} />
                    */}
        </FadeInUp>

      </Container>
      <Footer></Footer>
    </div>
  );
}

export default Management;
