import React from "react";
import { Container,Box } from "@mui/material";
import ManageServiceCard from "../../components/MaintenancePageComponents/ManageServiceCard";
import OurServices from "../../components/MaintenancePageComponents/OurServices";
import { Helmet } from 'react-helmet-async';
import Footer from "../../components/Footer";
import PackageTable from "../../components/MaintenancePageComponents/packageTable";


function Management({ title }) {
  return (
    <div>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <ManageServiceCard></ManageServiceCard>
      <Container>
        <OurServices></OurServices>
        {/*<AboutMaintenance></AboutMaintenance>*/}
      </Container>
      <Container className="pl-2 pr-2"  sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: { xs: "center", md: "space-between" },
              alignItems: { xs: "center", md: "flex-start" },
            }}>
      <PackageTable></PackageTable>
        </Container>
      <Footer></Footer>
    </div>
  );
}

export default Management;
