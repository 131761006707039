import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Card,
  CardContent,
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Snackbar,
  Collapse,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import axios from "axios";
import { Alert } from "@mui/material";

const TicketList = ({ ticketsAPI, onOpenTicketsCountChange }) => {
  const [newComment, setNewComment] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [currentTicketID, setCurrentTicketID] = useState(null);
  const [openComments, setOpenComments] = useState({});
  const [tickets, setTickets] = useState(ticketsAPI);
  const [filter, setFilter] = useState("showOpen"); // Default filter to show Open
  const apiUrl = process.env.REACT_APP_API_URL;

  // Filter tickets based on selected filter
  const filteredTickets = tickets.filter((ticket) => {
    if (filter === "showOpen") {
      return ticket.Status === "Open";
    } else if (filter === "showAwaitingResponse") {
      return ticket.Status === "Awaiting Response";
    } else if (filter === "showClosed") {
      return ticket.Status === "Completed";
    }
    return true; // Default to show all if filter doesn't match
  });

  useEffect(() => {
    const openTicketsCount = tickets.filter(
      (ticket) => ticket.Status === "Open"
    ).length;
    onOpenTicketsCountChange(openTicketsCount);
  }, [tickets, onOpenTicketsCountChange]);

  const handleAddComment = async (ticketID) => {
    if (!newComment) return;

    try {
      const formData = new FormData();
      formData.append("ticketID", ticketID);
      formData.append("commentText", newComment);
      formData.append("commentType", "Customer");

      const response = await axios.post(`${apiUrl}/ticketcomment`, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        const updatedComment = {
          CommentID: response.data.commentID,
          CommentText: newComment,
          CommentType: "Internal",
          TicketID: ticketID,
          CommentedAt: new Date().toISOString(),
        };

        setTickets((prevTickets) =>
          prevTickets.map((ticket) =>
            ticket.TicketID === ticketID
              ? {
                  ...ticket,
                  Comments: [...ticket.Comments, updatedComment],
                  Status: "Awaiting Response", // Update status here
                }
              : ticket
          )
        );

        setNewComment("");
        setOpenSnackbar(true);
        setCurrentTicketID(ticketID);
      } else {
        console.error("Failed to add comment:", response);
      }
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };

  const handleMarkAsSolved = async (ticketID) => {
    try {
      const token = localStorage.getItem("jwtToken");

      const ticketDataJSON = {
        ticketID: ticketID,
      };

      const response = await fetch(`${apiUrl}/completeticket`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        credentials: "include",
        body: JSON.stringify(ticketDataJSON),
      });

      if (response.status === 200) {
        setTickets((prevTickets) =>
          prevTickets.map((ticket) =>
            ticket.TicketID === ticketID
              ? { ...ticket, Status: "Closed" } // Update status here
              : ticket
          )
        );

        setOpenSnackbar(true);
        setCurrentTicketID(ticketID);
      } else {
        console.error("Failed to mark ticket as solved:", response);
      }
    } catch (error) {
      console.error("Error marking ticket as solved:", error);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleToggleComments = (ticketID) => {
    setOpenComments((prev) => ({ ...prev, [ticketID]: !prev[ticketID] }));
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Ticket List
      </Typography>
      <FormControl variant="outlined" sx={{ marginBottom: 2, minWidth: 200 }}>
        <InputLabel>Filter</InputLabel>
        <Select
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          label="Filter"
        >
          <MenuItem value="showOpen">Open Requests</MenuItem>
          <MenuItem value="showAwaitingResponse">
            Requests Awaiting Response
          </MenuItem>
          <MenuItem value="showClosed">Completed Requests</MenuItem>
        </Select>
      </FormControl>

      {/* Display message when no tickets match the filter */}
      {filteredTickets.length === 0 ? (
        <Typography variant="h6" color="textSecondary">
          No tickets found for the selected filter.
        </Typography>
      ) : (
        filteredTickets.map((ticket) => (
          <Card
            key={ticket.TicketID}
            variant="outlined"
            sx={{
              marginBottom: 2,
              borderColor: "lightgray",
              boxShadow: 1,
              borderRadius: "20px",
              transition: "transform 0.3s",
            }}
          >
            {/* Conditional Banner for Open Tickets */}
            {ticket.Status === "Open" && (
              <Typography
                variant="subtitle1"
                sx={{
                  backgroundColor: "#ffeb3b", // Yellow background for banner
                  padding: 1,
                  textAlign: "center",
                  fontWeight: "bold",
                  borderRadius: "20px 20px 0 0", // Rounded top corners
                }}
              >
                Reply Now
              </Typography>
            )}

            <CardContent>
              <Typography variant="h6">{ticket.Issue}</Typography>
              <Typography color="textSecondary">{ticket.Name}</Typography>
              <Typography color="textSecondary">Status: {ticket.Status}</Typography>
              <Typography color="textSecondary">TicketID: {ticket.TicketID}</Typography>
              <Typography color="textSecondary">
                Created At: {new Date(ticket.CreatedAt).toLocaleString()}
              </Typography>
              <Button
                onClick={() => handleToggleComments(ticket.TicketID)}
                sx={{
                  marginTop: 2,
                  backgroundColor: "#0c121b",
                  color: "#ffffff",
                  "&:hover": {
                    backgroundColor: "#212a38",
                    color: "#ffffff",
                  },
                  transition: "background-color 0.3s, transform 0.2s",
                }}
              >
                {openComments[ticket.TicketID] ? "Hide Comments" : "Show Comments"}
              </Button>

              {filter !== "showClosed" && (
                <Button
                  onClick={() => handleMarkAsSolved(ticket.TicketID)}
                  variant="contained"
                  color="success"
                  sx={{
                    marginLeft: 1,
                    marginTop: 2,
                    "&:hover": {
                      backgroundColor: "#2d394b",
                    },
                  }}
                >
                  Mark as Solved
                </Button>
              )}

              <Collapse in={openComments[ticket.TicketID]} timeout="auto" unmountOnExit>
                <List>
                  {ticket.Comments.map((comment) => (
                    <ListItem
                      key={comment.CommentID}
                      sx={{
                        justifyContent:
                          comment.CommentType === "Customer" ? "flex-start" : "flex-end",
                      }}
                    >
                      {comment.CommentType !== "Customer" && (
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              backgroundColor: "#1976d2",
                              marginLeft: 1,
                            }}
                          >
                            {comment.CommentType.charAt(0) || "U"}
                          </Avatar>
                        </ListItemAvatar>
                      )}

                      <ListItemText
                        primary={comment.CommentText}
                        secondary={new Date(comment.CommentedAt).toLocaleString()}
                        sx={{
                          textAlign: comment.CommentType === "Customer" ? "right" : "left",
                          marginLeft: comment.CommentType === "Customer" ? 0 : "auto",
                        }}
                      />

                      {comment.CommentType === "Customer" && (
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              backgroundColor: "#0c121b",
                              marginLeft: 1,
                            }}
                          >
                            {ticket.Name.charAt(0) || "U"}
                          </Avatar>
                        </ListItemAvatar>
                      )}
                    </ListItem>
                  ))}
                </List>
                {filter !== "showClosed" && (
                  <TextField
                    label="Add a comment"
                    variant="outlined"
                    fullWidth
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                    sx={{ marginTop: 2 }}
                  />
                )}
                {filter !== "showClosed" && (
                  <Button
                    onClick={() => handleAddComment(ticket.TicketID)}
                    variant="contained"
                    color="primary"
                    sx={{
                      marginTop: 2,
                      backgroundColor: "#0c121b",
                      color: "#ffffff",
                      "&:hover": {
                        backgroundColor: "#212a38",
                        color: "#ffffff",
                      },
                      transition: "background-color 0.3s, transform 0.2s",
                    }}
                  >
                    Add Comment
                  </Button>
                )}
              </Collapse>
            </CardContent>
          </Card>
        ))
      )}

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: "100%" }}>
          Ticket #{currentTicketID} has been updated!
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default TicketList;
