import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { Box, Button, Typography, Modal, LinearProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete'; // Import Delete icon
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Import success icon (green check)
import ErrorIcon from '@mui/icons-material/Error'; // Import error icon (red X)

const ImageUpload = ({ urluuid, formSubmitted }) => {
    const [images, setImages] = useState([]);
    const apiUrl = process.env.REACT_APP_API_URL;
    const [selectedImage, setSelectedImage] = useState(null); // Track selected image for modal
    const [open, setOpen] = useState(false); // Modal state
    const [isUploading, setIsUploading] = useState(false); // State to track upload status

    // Handle file drop
    const handleDrop = (acceptedFiles) => {
        // Limit to a maximum of 2 images
        if (images.length >= 2) {
            alert("You can only upload a maximum of 2 images.");
            return;
        }

        const filesWithPreview = acceptedFiles.map((file) => ({
            file,
            preview: URL.createObjectURL(file), // Create a preview URL for displaying
            progress: 0, // Initial progress at 0
            status: 'pending', // Upload status (pending, success, error)
        }));

        // Combine new files with existing images
        setImages((prevImages) => [...prevImages, ...filesWithPreview].slice(0, 2)); // Ensure only 2 images max
    };

    // Initialize the dropzone
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: handleDrop,
        accept: 'image/*', // Accept only image files
    });

    // Function to handle image deletion
    const handleDelete = (index) => {
        setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    };

    // Function to open modal with selected image
    const handleImageClick = (image) => {
        setSelectedImage(image);
        setOpen(true); // Open modal
    };

    // Function to close modal
    const handleClose = () => {
        setOpen(false);
        setSelectedImage(null); // Clear selected image
    };

    // Function to handle submission (uploads)
    const handleSubmit = async () => {
        if (images.length === 0) {
            console.log("No images to upload.");
            return;
        }

        if (images.length === 1) {
            setImages((prevImages) => [...prevImages, prevImages[0]]); // Duplicate the first image
        }
        setIsUploading(true); // Set uploading state to true

        for (let index = 0; index < images.length; index++) {
            const { file } = images[index]; // Access the File object
            const fileName = `${urluuid}_${index + 1}_FP.${file.name.split('.').pop()}`; // Use imageX.ext format for filename
            const dbURL = `https://wpms-s3.s3.eu-west-1.amazonaws.com/properties/${urluuid}/${fileName}`;
            const type = "floorplan";
            try {
                // Step 1: Request presigned URL
                const response = await axios.post(
                    `${apiUrl}/generate-presigned-url`,
                    { urluuid, fileName },
                    { withCredentials: true } // Include cookies for authentication
                );

                const response2 = await axios.post(
                    `${apiUrl}/updateimages`,
                    { urluuid, dbURL, type },
                    { withCredentials: true } // Include cookies for authentication
                );

                const { presignedUrl } = response.data;

                // Step 2: Upload the image to S3 using the presigned URL with progress tracking
                await axios.put(presignedUrl, file, {
                    headers: {
                        "Content-Type": file.type, // Ensure the correct Content-Type for the image
                    },
                    onUploadProgress: (progressEvent) => {
                        const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        // Update the progress for the specific image
                        setImages((prevImages) => {
                            const newImages = [...prevImages];
                            newImages[index].progress = progress;
                            return newImages;
                        });
                    },
                });

                console.log(`Uploaded ${fileName} successfully.`);
                // Mark the image upload as successful
                setImages((prevImages) => {
                    const newImages = [...prevImages];
                    newImages[index].status = 'success';
                    return newImages;
                });
            } catch (error) {
                console.error(`Error uploading ${fileName}:`, error);
                // Mark the image upload as failed
                setImages((prevImages) => {
                    const newImages = [...prevImages];
                    newImages[index].status = 'error';
                    return newImages;
                });
            }

            // Step 3: Add a 1-second delay before the next request
            await new Promise(resolve => setTimeout(resolve, 500));
        }
            // Reset form after upload completes
        setImages([]); // Clear the images state
        setIsUploading(false); // Set uploading state to false
    };

    return (
        <Box sx={{ padding: '20px' }}>
            <Box 
                {...getRootProps({ className: 'dropzone' })} 
                sx={{
                    border: '2px dashed #0c121b',
                    borderRadius: '5px',
                    padding: '20px',
                    textAlign: 'center',
                    cursor: 'pointer',
                    marginBottom: '20px',
                    backgroundColor: '#f5f5f5',
                    transition: 'border 0.2s ease',
                }}
            >
                <input {...getInputProps()} />
                <Typography variant="body1">
                    Drag & drop some images here, or click to select images (Max 2)
                </Typography>
            </Box>

            <Box 
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '20px', // Larger gap to space out larger images
                }}
            >
                {images.map((image, index) => (
                    <Box 
                        key={index} 
                        sx={{
                            position: 'relative',
                            width: '200px', // Larger image preview
                            height: '200px', // Larger image preview
                            textAlign: 'center',
                        }}
                    >
                        {/* Image Click Handler for Modal */}
                        <img 
                            src={image.preview} 
                            alt={`preview-${index}`} 
                            style={{ 
                                width: '100%', 
                                height: '100%', 
                                objectFit: 'cover', 
                                borderRadius: '5px',
                                cursor: 'pointer', // Change cursor to pointer to indicate clickable
                            }} 
                            onClick={() => handleImageClick(image.preview)} // Open modal with image
                        />

                        {/* Delete Button */}
                        <Button 
                            onClick={() => handleDelete(index)} 
                            sx={{
                                position: 'absolute',
                                top: '5px',
                                right: '5px',
                                minWidth: '20px', // Smaller button size
                                minHeight: '20px', // Smaller button size
                                bgcolor: 'error.main',
                                color: 'white',
                                fontSize: '10px', // Smaller font size
                                '&:hover': {
                                    bgcolor: 'error.dark',
                                },
                            }}
                        >
                            <DeleteIcon fontSize="small" /> {/* Trash can icon */}
                        </Button>

                        {/* Show progress bar while uploading */}
                        {image.progress < 100 && (
                            <LinearProgress 
                                variant="determinate" 
                                value={image.progress} 
                                sx={{
                                    position: 'absolute',
                                    bottom: '0',
                                    width: '100%',
                                    height: '5px',
                                }}
                            />
                        )}

                        {/* Show success/failure icon based on status */}
                        {image.status === 'success' && (
                            <CheckCircleIcon 
                                sx={{
                                    position: 'absolute',
                                    top: '5px',
                                    left: '5px',
                                    color: 'green',
                                    fontSize: '30px',
                                }} 
                            />
                        )}
                        {image.status === 'error' && (
                            <ErrorIcon 
                                sx={{
                                    position: 'absolute',
                                    top: '5px',
                                    left: '5px',
                                    color: 'red',
                                    fontSize: '30px',
                                }} 
                            />
                        )}

                        {/* Display image name as imageX.ext format */}
                        <Typography 
                            variant="caption" 
                            sx={{ 
                                marginTop: '8px', 
                                display: 'block', 
                                wordWrap: 'break-word',
                            }}
                        >
                            {`image${index + 1}.${image.file.name.split('.').pop()}`} {/* Display imageX.ext format */}
                        </Typography>
                    </Box>
                ))}
            </Box>

            {/* Disable submit button until exactly 2 images are added */}
            <Button 
                onClick={handleSubmit} 
                variant="contained" 
                disabled={(images.length < 1 || urluuid.length < 1) || isUploading || !formSubmitted} // Disable button until 2 images are added
                sx={{ marginTop: '20px', bgcolor: '#0c121b', color: 'white', '&:hover': { bgcolor: '#293444' } }} // Button color updated
            >
                Upload Floorplan
            </Button>

            {/* Modal for Enlarged Image */}
            <Modal
                open={open}
                onClose={handleClose}
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <Box 
                    sx={{ 
                        position: 'relative',
                        maxWidth: '80%', // Set max width for modal
                        maxHeight: '80%', // Set max height for modal
                        backgroundColor: 'white',
                        borderRadius: '5px',
                        overflow: 'hidden', // Prevent overflow
                    }}
                >
                    <img src={selectedImage} alt="Enlarged preview" style={{ width: '100%', height: 'auto' }} />
                    <Button 
                        onClick={handleClose} 
                        sx={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            minWidth: '30px', // Smaller button size
                            minHeight: '30px', // Smaller button size
                            bgcolor: 'error.main',
                            color: 'white',
                            '&:hover': {
                                bgcolor: 'error.dark',
                            },
                        }}
                    >
                        <DeleteIcon fontSize="small" /> {/* Close icon */}
                    </Button>
                </Box>
            </Modal>
        </Box>
    );
};

export default ImageUpload;
