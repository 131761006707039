import React from "react";
import discoverImg from '../../assets/images/fix.jpg';
import { Box, Container, Divider, Typography } from "@mui/material";

import ManageServiceCardChild from '../../components/MaintenancePageComponents/ManageServiceCardChild';
import { Helmet } from 'react-helmet-async';
import {
  AutoAwesome,
  Handyman,
  SupportAgent,
  Loyalty,
  ManageHistory,
  PublishedWithChanges,
  CameraRoll,
} from "@mui/icons-material";
import Footer from "../../components/Footer";
import FadeInUp from "../../components/FadeInUp";


function Management({ title }) {
  return (
    <div>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Box sx={{
        height: '75vh',
        backgroundImage: `url(${discoverImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex'
    }}>
        <ManageServiceCardChild variant='h5' name='Maintenance' description='For when things go wrong, our team are here to assist and repair.'/>
    </Box>
    <Container>
          <FadeInUp>
          <Typography
            variant="h2"
            sx={{
              fontSize: "clamp(1.8rem, 1.3654rem + 0.9065vw, 2rem)",
              textTransform: "uppercase",
              marginBottom: "1rem",
              marginTop: "1rem",
              textAlign: "center",
            }}
          >
            Maintenance
          </Typography>
          <Divider></Divider>
            <Divider></Divider>
            <Box display="flex" alignItems="center" sx={{marginTop: "0.5rem"}}>
              <Handyman sx={{ marginRight: "0.5rem" }} />{" "}
              {/* Add margin to the right */}
              <Typography
                variant="h5"
                sx={{
                  textTransform: "uppercase",
                  marginBottom: "0.25rem",
                  marginTop: "0.25rem",
                  textAlign: "left",
                }}
              >
                Strong Partner Network
              </Typography>
            </Box>
            <Typography>
              We understand how important it is to resolve maintenance issues
              swiftly to keep your property in top condition. From plumbing
              leaks to electrical faults, no issue is too big or small. Thanks
              to our extensive network of skilled tradesmen, we are able to
              attend to any problem with speed and efficiency.
            </Typography>
            <Box display="flex" alignItems="center" sx={{marginTop: "0.5rem"}}>
              <SupportAgent sx={{ marginRight: "0.5rem" }} />{" "}
              {/* Add margin to the right */}
              <Typography
                variant="h5"
                sx={{
                  textTransform: "uppercase",
                  marginBottom: "0.25rem",
                  marginTop: "0.25rem",
                  textAlign: "left",
                }}
              >
                Dedicated Ticketing System
              </Typography>
            </Box>
            <Typography>
              We also offer a dedicated in-house maintenance request system so
              you can reach us directly from wherever, whenever.
            </Typography>
            <Box display="flex" alignItems="center" sx={{marginTop: "0.5rem"}}>
              <ManageHistory sx={{ marginRight: "0.5rem" }} />{" "}
              {/* Add margin to the right */}
              <Typography
                variant="h5"
                sx={{
                  textTransform: "uppercase",
                  marginBottom: "0.25rem",
                  marginTop: "0.25rem",
                  textAlign: "left",
                }}
              >
                Speed is our priority
              </Typography>
            </Box>
            <Typography sx={{marginBottom: "1.5rem"}}>
              Our trusted team of professionals are available around the clock,
              ensuring that repairs are handled promptly and to the highest
              standard. With us, you can have peace of mind knowing your
              property is in good hands.
            </Typography>
          </FadeInUp>
        </Container>
      <Footer></Footer>
    </div>
  );
}

export default Management;
