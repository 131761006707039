import React from "react";
import { Box, Container, Divider, Typography } from "@mui/material";
import FlipCard from "./FlipCard2";
import {
  AutoAwesome,
  Handyman,
  SupportAgent,
  Loyalty,
  ManageHistory,
  PublishedWithChanges,
  CameraRoll,
} from "@mui/icons-material";
import ImageGallerySlider from "../ImageGallerySlider";
import img1 from "../../assets/images/rent-header-img.jpg";
import img2 from "../../assets/images/rent-header-img.jpg";
import FadeInUp from "../FadeInUp";
import StagingFacts from "./StagingFacts";

function WhyRentSection() {
  const images = [
    {
      original: img1,
      thumbnail: img1,
    },
    {
      original: img2,
      thumbnail: img2,
    },
  ];

  return (
    <Box sx={{ marginBottom: "3rem" }}>
      <Container
        sx={{
          paddingY: "1rem",
        }}
      >
        <FadeInUp>
          <Typography
            variant="h4"
            sx={{
              textAlign: "center",
              marginBottom: "1rem",
            }}
          >
            THE 3 Ms
          </Typography>
          <Divider></Divider>
          <Typography sx={{textAlign: "center", marginBottom: "1rem"}}> We’re all about the 3
            Ms—Maintenance, Marketing, and Management—to help you get the most
            out of your investment. We’ll keep your property in top shape,
            market it to attract great tenants, and handle all the day-to-day
            stuff. With us on your side, you can kick back and enjoy the
            benefits without all the stress. Let us take care of the heavy
            lifting while you relax!

            Check them out below.
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: { xs: "center", md: "space-between" },
              alignItems: { xs: "center", md: "flex-start" },
            }}
          >
            <FlipCard />
          </Box>
        </FadeInUp>
        <Box
          sx={{
            width: { xs: "100%", md: "50%" },
            display: "flex",
            justifyContent: "center",
            paddingLeft: { md: 0, lg: "6rem" },
            paddingTop: { xs: "2rem", md: 0 },
          }}
        />
      </Container>
      <Container>
        <FadeInUp>
          <Typography
            variant="h2"
            sx={{
              fontSize: "clamp(1.8rem, 1.3654rem + 0.9065vw, 2rem)",
              textTransform: "uppercase",
              marginBottom: "1rem",
              marginTop: "1rem",
              textAlign: "center",
            }}
          >
            Management
          </Typography>
          <Divider></Divider>
          <Typography sx={{textAlign: "center"}}>
            Managing a rental property can be time-consuming and stressful, but
            with our comprehensive property and tenant management service,
            landlords can relax while we handle everything. From tenant sourcing
            and screening to rent collection and maintenance coordination, we
            take care of every aspect of managing your property. Our experienced
            team ensures that your property is well-maintained, your tenants are
            satisfied, and your income is steady. With transparent communication
            and a proactive approach, we provide peace of mind for landlords, so
            you can enjoy the benefits of your investment without the hassle.
          </Typography>
        </FadeInUp>
        
      </Container>
      {/*
      <Container>
        <FadeInUp>
          <Typography
            variant="h2"
            sx={{
              fontSize: "clamp(1.8rem, 1.3654rem + 0.9065vw, 2rem)",
              textTransform: "uppercase",
              marginBottom: "0.25rem",
              marginTop: "1.00rem",
              textAlign: "center",
            }}
          >
            Staging
          </Typography>
          <Divider></Divider>
          <Box display="flex" alignItems="center">
            <AutoAwesome sx={{ marginRight: "0.5rem" }} />{" "}
            <Typography
              variant="h6"
              sx={{
                textTransform: "uppercase",
                marginBottom: "0.25rem",
                fontWeight: "bold",
                marginTop: "0.25rem",
                textAlign: "left",
              }}
            >
              What is Staging?
            </Typography>
          </Box>
          <Typography>
            Property staging is the process of preparing a home to make it more
            attractive to buyers or renters. It involves arranging furniture,
            decor, and making minor improvements to highlight the property's
            best features and create an inviting atmosphere. This can help sell
            or rent the property faster and potentially at a higher price.
          </Typography>
          <Box display="flex" alignItems="center">
            <CameraRoll sx={{ marginRight: "0.5rem" }} />{" "}
            <Typography
              variant="h6"
              sx={{
                textTransform: "uppercase",
                marginBottom: "0.25rem",
                fontWeight: "bold",
                marginTop: "0.25rem",
                textAlign: "left",
              }}
            >
              Videography/Photography Packages
            </Typography>
          </Box>
          <Typography>
            We also offer packages of professional videography and photography
            which help boost property sales and leases by showcasing the
            property in high quality, attracting more interest, and enhancing
            online visibility. They help potential buyers or renters envision
            themselves in the space, improve the property's perceived value, and
            provide a competitive edge in a crowded market.
          </Typography>
          <div></div>
          <Typography
            sx={{
              marginBottom: "0.5rem",
              marginTop: "0.5rem",
              textAlign: "left",
            }}
          >
            Just after photography and videography? We can also arrange this
            too.
          </Typography>
          <Box display="flex" alignItems="center">
            <Loyalty sx={{ marginRight: "0.5rem" }} />{" "}
            <Typography
              variant="h6"
              sx={{
                textTransform: "uppercase",
                marginBottom: "0.25rem",
                fontWeight: "bold",
                marginTop: "0.25rem",
                textAlign: "left",
              }}
            >
              Did you know?
            </Typography>
          </Box>
          <Typography>
            Over 70% of houses that are staged reach a sale agreed/let agreed
            quicker than houses that are not staged. Check the differences for
            yourself!
          </Typography>
          <ImageGallerySlider images={images} />
          <StagingFacts></StagingFacts>
        </FadeInUp>
            </Container> */}
    </Box>
  );
}

export default WhyRentSection;
