let items = [
    {
        name: "Ward Property Management",
        description: "Ward Property Management specialise in property management, rental and staging in Northern Ireland.",
        img: "./assets/images/house6.jpg",
        bgPosition: "right",
        gradient: "to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.35)",
        direction: "row",
        buttonTitle: "Our services",
        buttonLink: "/services",
        title: "Properties"

    },
    {
        name: "Properties to rent",
        description: "Browse our stunning catalog of properties available to rent.",
        img: "./assets/images/living5.jpg",
        bgPosition: "center",
        gradient: "to left, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.35)",
        direction: "row-reverse",
        buttonTitle: "Properties available to rent",
        buttonLink: "/tenants",
        title: "Properties"
    },
    {
        name: "Rent your own property today",
        description: "We are here to help you rent and manage your own property with us. ",
        img: "./assets/images/living12.jpg",
        bgPosition: "center",
        gradient: "rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)",
        direction: "row-reverse",
        buttonTitle: "Rent your property",
        buttonLink: "/landlords",
        title: "Properties"

    },
];

export default items;