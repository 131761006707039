// src/App.js
import React, { useState, useEffect } from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Rent from "./pages/properties";
import RentProperty from "./pages/MainPropertyView";
import Landlords from "./pages/landlords/landlords";
import Navigation from "./components/Misc/Navigation2";
import Management from "./pages/management/Management";
import Maintenance from "./pages/management/maintenance";
import Marketing from "./pages/management/marketing";
import Contact from "./pages/Contact";
import ScrollToTop from "./components/ScrollToTop";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import SplashPage from "./components/Misc/SplashPage";
import SuperSecretLogin from "./components/Admin/Login";
import ServiceTicket from "./pages/ServiceTicket";
import Admin from "./pages/Admin";
import NotFound from "./pages/notfound";
import Pricing from "./pages/landlords/pricing";
import { HelmetProvider } from 'react-helmet-async'; // Import HelmetProvider

const theme = createTheme({
  typography: {
    fontFamily: ["Montserrat", "sans-serif"].join(","),
  },
});

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // Adjust the timing as needed

    return () => clearTimeout(timer);
  }, []);

  const [isRandomPropertyUndefined, setIsRandomPropertyUndefined] = useState(false);

  // This function is passed down to Page to update the state when randomProperty changes
  const handleValueChange = (value) => {
    setIsRandomPropertyUndefined(value);
  };
  
  return (
    <HelmetProvider> {/* Wrap your app with HelmetProvider */}
    
      <ThemeProvider theme={theme}>
        {/* Main content */}
        <div className="app-content">
          <ScrollToTop />
          <Navigation value={isRandomPropertyUndefined}></Navigation>     
          <Routes>
            <Route path="/" element={<Home title="Home - Ward Property" />} />
            <Route path="/services/property-maintenance" element={<Maintenance title="Services - Maintenance" />} />
            <Route path="/services/property-management" element={<Management title="Services - Management" />} />
            <Route path="/serviceticket" element={<ServiceTicket title="Services" />} />
            <Route path="/services/property-marketing" element={<Marketing title="Services - Marketing" />} />
            <Route path="/properties" element={<Rent title="Properties - Ward Property" setValue={handleValueChange}/>} />
            <Route path="/maintenancerequests" element={<ServiceTicket title="Maintenance Requests - Ward Property" />} />
            <Route path="/landlords" element={<Landlords title="Landlords - Ward Property" />} />
            <Route path="/landlords/pricing" element={<Pricing title="Landlords - Pricing" />} />
            <Route path="/landlords/how" element={<Pricing title="Landlords - Pricing" />} />
            <Route path="/property/:id" element={<RentProperty title="Property Details" />} />
            <Route path="/contact" element={<Contact title="Contact Us - Ward Property" />} />
            <Route path="/adminloginui" element={<Admin title="Admin Login" />} />
            <Route path="/supersecretlogin" element={ <div><SuperSecretLogin /> </div>} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>

        {/* Splash Overlay */}
        <div className={`splash-page ${loading ? "" : "hidden"}`}>
          <SplashPage />
        </div>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
