import React from "react";
import { Box, Button, Typography } from "@mui/material";

function ContactForm() {
  const emailAddress = "zak@wardpropertymanagement.co.uk"; // Replace with your email address
  const subject = "Ward Enquiry"; // The subject of the email
  const body = "Hello, I would like to inquire about..."; // The body of the email

  return (
    <Box sx={{ width: { sx: "100%", md: "60%" } }}>
      <Typography variant="h6" component="h2" sx={{ marginBottom: "1rem" }}>
        If you have any questions, feel free to reach out to us:
      </Typography>
      
      {/* Button to open default email client */}
      <Button
        variant="contained"
        size="large"
        sx={{
          backgroundColor: "#0c121b",
          color: "#FFFFFF",
          "&:hover": {
            backgroundColor: "#2d394b",
          },
        }}
      >
        <a
          href={`mailto:${emailAddress}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`}
          style={{ color: "inherit", textDecoration: "none" }}
        >
          Send Email
        </a>
      </Button>
    </Box>
  );
}

export default ContactForm;
