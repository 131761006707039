import React from 'react';
import { Box } from '@mui/material';
import discoverImg from '../assets/images/Rent-A-House-As-A-Landlord-1280x854-1.jpeg';
import ManageServiceCardChild from './MaintenancePageComponents/ManageServiceCardChild';

function RentWithUsCard() {
  return (
    <Box sx={{
        height: '75vh',
        backgroundImage: `url(${discoverImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex'
    }}>
        <ManageServiceCardChild variant='h5' name='Rent With Ward' description='Thinking of renting your property? Choose Ward for a reliable service, guiding you through the process for new and experienced landlords.'/>
    </Box>
  )
}

export default RentWithUsCard;