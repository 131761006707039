const contactCards = [
    {
        id: 1,
        icon: './assets/icons/envelope-fill.svg',
        title: 'Email',
        description: 'Email us',
        url: 'mailto: zak@wardpropertymanagement.co.uk'
    },
    {
        id: 2,
        icon: './assets/icons/telephone-fill.svg',
        title: 'Phone',
        description: 'Coming Soon',
        url: 'tel:0900800700'
    },
    {
        id: 3,
        icon: './assets/icons/building-fill.svg',
        title: 'Address',
        description: '98 High Street, Bangor, BT20 5DH',
        url: null
    },
];

export default contactCards;