import React, { useState } from "react";
import axios from "axios";
import {
  Container,
  TextField,
  Button,
  Box,
  Typography,
  Paper,
  Alert,
} from "@mui/material";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);

  const handleLogin = async () => {
    try {
      const response = await axios.get("https://wardpropertymanagement.co.uk/api/v1/auth.php", {
        params: {
          username: email,
          password: password,
        },
      });

      if (response.status === 200) {
        // Simulate granting a session by storing user info or token in localStorage (can be replaced with proper auth)
        localStorage.setItem("session", JSON.stringify({ email }));
        setSuccess(true);
        setErrorMessage("");

        // Redirect to dashboard or another page (replace '/dashboard' with your actual route)
        window.location.href = "/home";
      }
    } catch (error) {
      // Handle error, show message
      setErrorMessage("Login failed. Please check your email and password.");
      setSuccess(false);
    }
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: 4,
          borderRadius: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Typography variant="h5" component="h1" gutterBottom>
          Login
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "100%",
          }}
        >
          <TextField
            label="Username"
            variant="outlined"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="Password"
            variant="outlined"
            type="password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleLogin}
            sx={{
              textAlign: "center",
              fontWeight: "600",
              color: "#fffff",
              backgroundColor: "#0c121b",
              overflowWrap: "anywhere",
            }}
          >
            Login
          </Button>
          {success && (
            <Alert severity="success">Login successful! Redirecting...</Alert>
          )}
        </Box>
      </Paper>
    </Container>
  );
};

export default LoginPage;
