import React, { useState, useEffect } from 'react';
import { Fab, Box } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { keyframes } from '@emotion/react';

// Fade-out keyframes animation
const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

// Fade-in keyframes animation
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

// Debounce function to limit how often a function is called
const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};

const FloatingButton = () => {
  const [isVisible, setIsVisible] = useState(true);  // Controls button visibility
  const [isHidden, setIsHidden] = useState(false);   // Controls if the button is rendered or not
  const [recentlyShown, setRecentlyShown] = useState(false); // Flag to control re-showing

  // Scroll event handler with a range check
  useEffect(() => {
    const handleScroll = debounce(() => {
      const scrollTop = window.scrollY;

      if (scrollTop <= 10) {
        // Only show if it wasn't recently shown
        if (!recentlyShown) {
          setIsHidden(false); // Make sure the button is no longer hidden
          setIsVisible(true);  // Trigger fade-in animation
          setRecentlyShown(true); // Set the flag to prevent re-showing
          
          setTimeout(() => {
            // Reset the recentlyShown flag after a short delay
            setTimeout(() => setRecentlyShown(false), 200); // Short delay for aggressive scrolling
          }, 1000); // Maintain button visibility for 1 second after fading in
        }
      } else if (scrollTop > window.innerHeight / 2) {
        // Fade-out the button when the user scrolls down past half the viewport height
        setIsVisible(false);
        setTimeout(() => {
          setIsHidden(true);  // Hide the button after fade-out animation
        }, 1000);  // Match the fade-out duration (1s) to when it should fully disappear
      }
    }, 100);  // Reduced debounce delay for quicker scroll response

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [recentlyShown]); // Add recentlyShown to the dependency array

  const handleScrollDown = () => {
    // Trigger fade-out and then hide the button
    setIsVisible(false);
    setTimeout(() => {
      setIsHidden(true);
    }, 1000); // Wait for fade-out animation to finish before hiding it completely

    // Scroll down one full page height
    window.scrollTo({
      top: (window.innerHeight)*0.87,
      behavior: 'smooth',
    });
  };

  return (
    <>
      {!isHidden && (  // Only render the button if it's not hidden
        <Box
          sx={{
            position: 'fixed',
            bottom: 16,
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 1000,
            animation: isVisible ? `${fadeIn} 1s forwards` : `${fadeOut} 1s forwards`,
            opacity: isVisible ? 1 : 0,
          }}
        >
          <Fab
            sx={{
              backgroundColor: '#0c121b',  // Set background color
              color: '#fff',                // Set text/icon color to white
              '&:hover': {
                backgroundColor: '#2d394b', // Darker color on hover
              },
            }}
            aria-label="scroll down"
            onClick={handleScrollDown}
          >
            <ArrowDownwardIcon />
          </Fab>
        </Box>
      )}
    </>
  );
};

export default FloatingButton;
