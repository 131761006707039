import React from "react";
import "./SplashPage.css"; // Import custom styling
import { BarLoader } from "react-spinners";
import image from "../../assets/icons/2-t.png";

const SplashPage = () => {
  return (
    <div className="splash-page">
      <img src={image} alt="Splash Image" className="splash-image" />
      <BarLoader color="#e5e5e5" size="30px" className="loader" />
    </div>
  );
};

export default SplashPage;
