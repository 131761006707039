import React, { useState, useEffect } from "react";
import axios from "axios";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  Container,
  Button,
  CircularProgress,
  Typography,
  Grid,
  TextField,
  Select,
  Modal,
  Box,
  MenuItem,
  FormControl,
  InputLabel,
  Fade,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AddIcon from "@mui/icons-material/Add";
import PropertyCoords from "./MapContainer";
import ImageUpload from "./ImageUploadComponent";
import ImageUpload2 from "./FloorPlanUpload";

const apiUrl = process.env.REACT_APP_API_URL;
const truncateString = (str) => {
  const firstPeriodIndex = str.indexOf(".");
  if (firstPeriodIndex !== -1) {
    return str.substring(0, firstPeriodIndex + 1); // Include the period
  }
  return str; // Return the whole string if no period is found
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "8px", // Rounded borders
  boxShadow: 24,
  p: 4,
  textAlign: "center",
  outline: "none", // Remove the black outline
};

const PropertyUploadForm = () => {
  const [formData, setFormData] = useState({
    title: "",
    address: "",
    price: "",
    shortDescription: "",
    longDescription: "",
    bedrooms: "",
    bathrooms: "",
    livingrooms: "",
    latitude: "",
    longitude: "",
    mainDescription: "",
    status: "",
    style: "",
    viewableFrom: "",
    leaseLength: "",
    deposit: "",
    energyRating: "",
    heatingType: "",
    furnishing: "",
    town: "",
    county: "",
    postcode: "",
  });

  const handleCoordinatesChange = (coordinates) => {
    setFormData((prevData) => ({
      ...prevData,
      latitude: coordinates[0], // Set latitude
      longitude: coordinates[1], // Set longitude
    }));
  };

  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [formSubmitted, setSubmitted] = useState(false);
  const [message, setMessage] = useState("");
  const [addressID, setID] = useState("");
  const [coordinates, setCoordinates] = useState(null);
  const [broadbandMaxDown, setbbmax] = useState("");
  const [broadbandMaxUp, setbbUpMax] = useState("");

  // Function to handle coordinates update from child component
  const handleCoordinates = (newCoordinates) => {
    setCoordinates(newCoordinates); // Update state with the new coordinates
  };

  const [features, setFeatures] = useState([""]); // Start with one empty text field

  const addFeatureField = () => {
    setFeatures([...features, ""]);
  };

  const removeFeatureField = (index) => {
    // Ensure index is valid
    if (index < 0 || index >= features.length) return;
    setFeatures(features.filter((_, i) => i !== index));
  };

  const handlefeatureChange = (index, event) => {
    const newFeatures = [...features];
    newFeatures[index] = event.target.value;
    setFeatures(newFeatures);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const [buttonDisabled, setButtonDisabled] = useState(false);

  const [open, setOpen] = useState(false); // State for modal open/close

  useEffect(() => {
    let timer;
    if (open) {
      timer = setTimeout(() => {
        setOpen(false);
      }, 3000); // Close the modal after 3 seconds
    }
    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, [open]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Fetch broadband data
    try {
      const broadbandApiUrl = `https://wardpropertymanagement.co.uk/api/v1/integrations/broadband.php?postcode=${formData.postcode}`; // Replace with the actual API URL
      const response = await fetch(broadbandApiUrl);

      if (!response.ok) {
        throw new Error(`Error fetching data: ${response.status}`);
      }

      const data = await response.json();
      setbbmax(data.maxPredictedDown); // Assign fetched values to JS variables
      setbbUpMax(data.maxPredictedUp);
    } catch (error) {
      console.error("Error fetching broadband data:", error);
      setMessage(
        "An error occurred while fetching broadband data: " + error.message
      );
      return; // Exit if there was an error fetching the broadband data
    }

    // Create shortDescription from longDescription
    const shortDescription = truncateString(formData.longDescription, 27);
    const urluuid = formData.address
      .replace(/[ ,]/g, "-")
      .replace(/-{2,}/g, "-");

    const updatedFormData = {
      ...formData, // Spread the existing formData
      shortDescription, // Add shortDescription
      features,
      urluuid,
      broadbandMaxUp, // Add maxPredictedDown to the form data
      broadbandMaxDown, // Add maxPredictedUp to the form data
    };

    const jsonString = JSON.stringify(updatedFormData);
    setUploading(true);
    try {
      const response = await axios.post(`${apiUrl}/addproperty`, jsonString, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });

      setMessage(response.data.message || response.data.error);
      setOpen(true);
      setFormData({
        title: "",
        address: "",
        price: "",
        shortDescription: "",
        longDescription: "",
        bedrooms: "",
        bathrooms: "",
        livingrooms: "",
        latitude: "",
        longitude: "",
        mainDescription: "",
        status: "",
        style: "",
        viewableFrom: "",
        leaseLength: "",
        deposit: "",
        energyRating: "",
        heatingType: "",
        furnishing: "",
        postcode: "",
        town: "",
      });
      setSubmitted(true);
      setFeatures([""]);
    } catch (error) {
      setMessage("An error occurred: " + error.message);
    } finally {
      setUploading(false);
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Upload New Property
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {/* First Row */}
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              name="title"
              label="Title"
              fullWidth
              required
              value={formData.title}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              name="address"
              label="Address"
              fullWidth
              required
              value={formData.address}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              name="price"
              label="Price"
              fullWidth
              required
              type="number"
              value={formData.price}
              onChange={handleInputChange}
            />
          </Grid>

          {/* Second Row */}

          <Grid item xs={12} sm={6} md={4}>
            <TextField
              name="deposit"
              label="Deposit"
              required
              fullWidth
              type="number"
              value={formData.deposit}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <InputLabel id="bedrooms-label">Bedrooms</InputLabel>
              <Select
                labelId="bedrooms-label"
                name="bedrooms"
                required
                value={formData.bedrooms}
                onChange={handleInputChange}
                label="Bedrooms"
              >
                <MenuItem value="0">0</MenuItem>
                <MenuItem value="1">1</MenuItem>
                <MenuItem value="2">2</MenuItem>
                <MenuItem value="3">3</MenuItem>
                <MenuItem value="4">4</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <InputLabel id="bathrooms-label">Bathrooms</InputLabel>
              <Select
                labelId="bathrooms-label"
                name="bathrooms"
                required
                value={formData.bathrooms}
                onChange={handleInputChange}
                label="Bathrooms"
              >
                <MenuItem value="0">0</MenuItem>
                <MenuItem value="1">1</MenuItem>
                <MenuItem value="2">2</MenuItem>
                <MenuItem value="3">3</MenuItem>
                <MenuItem value="4">4</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <InputLabel id="livingrooms-label">Living Rooms</InputLabel>
              <Select
                labelId="livingrooms-label"
                name="livingrooms"
                required
                value={formData.livingrooms}
                onChange={handleInputChange}
                label="Livingrooms"
              >
                <MenuItem value="0">0</MenuItem>
                <MenuItem value="1">1</MenuItem>
                <MenuItem value="2">2</MenuItem>
                <MenuItem value="3">3</MenuItem>
                <MenuItem value="4">4</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <InputLabel id="furnishing-label">Furnishing</InputLabel>
              <Select
                labelId="furnishing-label"
                name="furnishing"
                required
                value={formData.furnishing}
                onChange={handleInputChange}
                label="Furnishing"
              >
                <MenuItem value="Unfurnished">Unfurnished</MenuItem>
                <MenuItem value="Furnished">Furnished</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Third Row */}
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <InputLabel id="style-label">Style</InputLabel>
              <Select
                labelId="style-label"
                name="style"
                required
                value={formData.style}
                onChange={handleInputChange}
                label="Style"
              >
                <MenuItem value="Apartment">Apartment</MenuItem>
                <MenuItem value="Detached House">Detached House</MenuItem>
                <MenuItem value="Terraced House">Terraced House</MenuItem>
                <MenuItem value="Semi-Detached House">
                  Semi-Detached House
                </MenuItem>
                <MenuItem value="End Terrace House">End Terrace House</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Fourth Row */}
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <InputLabel id="status-label">Status</InputLabel>
              <Select
                labelId="status-label"
                required
                name="status"
                value={formData.status}
                onChange={handleInputChange}
                label="Status"
              >
                <MenuItem value="To Let">To Let</MenuItem>
                <MenuItem value="Agreed">Agreed</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <InputLabel id="heatingtype-label">Heating Type</InputLabel>
              <Select
                labelId="heatingtype-label"
                name="heatingType"
                required
                value={formData.heatingType}
                onChange={handleInputChange}
                label="Heating Type"
              >
                <MenuItem value="Gas">Gas</MenuItem>
                <MenuItem value="Oil">Oil</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TextField
              name="energyRating"
              label="Energy Rating"
              fullWidth
              required
              value={formData.energyRating.toUpperCase()}
              onChange={handleInputChange}
              inputProps={{ maxLength: 3 }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <InputLabel id="county-label">County </InputLabel>
              <Select
                labelId="county-label"
                name="county"
                required
                value={formData.county}
                onChange={handleInputChange}
                label="County"
              >
                <MenuItem value="County Down">Down</MenuItem>
                <MenuItem value="County Armagh">Armagh</MenuItem>
                <MenuItem value="County Antrim">Antrim</MenuItem>
                <MenuItem value="County Tyrone">Tyrone</MenuItem>
                <MenuItem value="County Fermanagh">Fermanagh</MenuItem>
                <MenuItem value="County Londonderry">Londonderry</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TextField
              name="town"
              label="Town"
              fullWidth
              required
              value={formData.town.charAt(0).toUpperCase() + formData.town.slice(1).toLowerCase()}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TextField
              name="postcode"
              label="Postcode"
              fullWidth
              required
              value={formData.postcode.toUpperCase()}
              onChange={handleInputChange}
              inputProps={{ maxLength: 7 }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <InputLabel id="leaseLength-label">Lease Length</InputLabel>
              <Select
                labelId="leaseLength-label"
                name="leaseLength"
                required
                value={formData.leaseLength}
                onChange={handleInputChange}
                label="Lease Length"
              >
                <MenuItem value="12 Months">12 Months</MenuItem>
                <MenuItem value="6 Months">6 Months</MenuItem>
                <MenuItem value="Rolling">Rolling</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Fifth Row */}

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid item xs={12} sm={6} md={4}>
              <DatePicker
                label="Viewable From"
                required
                value={
                  formData.viewableFrom
                    ? dayjs(formData.viewableFrom, "ddd, DD MMM YYYY")
                    : null
                }
                onChange={(newValue) =>
                  handleInputChange({
                    target: { name: "viewableFrom", value: newValue },
                  })
                }
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Grid>
          </LocalizationProvider>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          marginTop="15px"
          marginBottom="20px"
          sx={{ textAlign: "left" }}
        >
          <Typography sx={{ textAlign: "left" }}>Features</Typography>
          {features.map((feature, index) => (
            <Box key={index} display="flex" alignItems="center" mb={2}>
              <TextField
                value={feature}
                onChange={(e) => handlefeatureChange(index, e)}
                placeholder="Enter feature"
                variant="outlined"
                sx={{ mr: 2 }}
              />
              {features.length > 1 && (
                <Button
                  onClick={() => removeFeatureField(index)}
                  variant="outlined"
                  sx={{
                    minWidth: "auto",
                    padding: "4px",
                    color: "grey.700", // Grey color for text
                    borderColor: "grey.400", // Grey color for border
                  }}
                >
                  <DeleteIcon fontSize="small" />
                </Button>
              )}
            </Box>
          ))}
          <Button
            onClick={addFeatureField}
            variant="contained"
            sx={{
              mr: 2,
              minWidth: "auto",
              padding: "4px",
              backgroundColor: "#9b9b9b", // Replace with desired color code
              "&:hover": {
                backgroundColor: "#787878", // Slightly darker grey on hover
              },
            }}
          >
            <AddIcon fontSize="small" />
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Long Description
          </Typography>
          <TextField
            name="longDescription"
            label="Brief Description"
            fullWidth
            required
            multiline
            rows={3}
            value={formData.longDescription}
            onChange={handleInputChange}
            variant="outlined"
          />
        </Grid>

        {/* Text Area for Main Description */}
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Main Description
          </Typography>
          <TextField
            name="mainDescription"
            label="Main Description"
            fullWidth
            multiline
            required
            rows={6}
            value={formData.mainDescription}
            onChange={handleInputChange}
            variant="outlined"
          />
        </Grid>
        <Typography variant="h6" gutterBottom>
          Property Location
        </Typography>
        <PropertyCoords onCoordinatesChange={handleCoordinatesChange} />

        <Button
          type="submit"
          variant="contained"
          disabled={buttonDisabled || uploading}
          sx={{
            marginTop: "2rem",
            marginBottom: "2rem",
            backgroundColor:
              buttonDisabled || uploading ? "#d3d3d3" : "#0c121b",
            "&:hover": {
              backgroundColor:
                buttonDisabled || uploading ? "#b0b0b0" : "#2d394b",
            },
          }}
        >
          Submit
        </Button>
      </form>
      <Typography variant="h6" gutterBottom>
        Property Photos
      </Typography>
      <ImageUpload
        urluuid={formData.address.replace(/[ ,]/g, "-").replace(/-{2,}/g, "-")}
        formSubmitted={formSubmitted}
      ></ImageUpload>
      <Typography variant="h6" gutterBottom>
        Property Floorplans
      </Typography>
      <ImageUpload2
        urluuid={formData.address.replace(/[ ,]/g, "-").replace(/-{2,}/g, "-")}
        formSubmitted={formSubmitted}
      ></ImageUpload2>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        closeAfterTransition
        BackdropComponent="div"
      >
        <Fade in={open} timeout={300}>
          <Box sx={modalStyle}>
            <CheckCircleIcon color="success" sx={{ fontSize: 40, mb: 2 }} />
            <Typography id="modal-title" variant="h6" component="h2">
              {message}
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </Container>
  );
};

export default PropertyUploadForm;
