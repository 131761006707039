import { CheckIcon } from '@heroicons/react/20/solid'

const includedFeatures = [
  'No hidden fees',
  'Quarterly Inspections',
  'Inventory checks',
  'Legal documents',
  'Gov backed TDS deposit scheme',
  'Maintenance and management',
  'No tenant find fee',
  'No fees until tenancy begins',
  'Tenant background checks',
  '+ much more',

]

export default function Example() {
  return (
    <div className="mx-auto mt-12 max-w-2xl shadow-lg rounded-3xl ring-1 ring-gray-200 sm:mt-16 lg:mx-0 lg:flex lg:max-w-none">
      <div className="p-8 sm:p-10 lg:flex-auto">
        <h3 className="text-2xl font-bold tracking-tight text-gray-900">Full Property Management</h3>
        <p className="mt-6 text-base leading-7 text-gray-600"></p>
        <div className="mt-10 flex items-center gap-x-4">
          <h4 className="flex-none text-sm font-semibold leading-6" style={{ color: '#0c121b' }}>
            What’s included
          </h4>
          <div className="h-px flex-auto bg-gray-100" />
        </div>
        <ul
          role="list"
          className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
        >
          {includedFeatures.map((feature) => (
            <li key={feature} className="flex gap-x-3">
              <CheckIcon aria-hidden="true" className="h-6 w-5 flex-none" style={{ color: '#0c121b' }} />
              {feature}
            </li>
          ))}
        </ul>
      </div>
      <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
        <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
          <div className="mx-auto max-w-xs px-8">
            <p className="text-base font-semibold text-gray-600">Paid yearly, or monthly.</p>
            <p className="mt-6 flex items-baseline justify-center gap-x-2">
              <span className="text-5xl font-bold tracking-tight text-gray-900">8%</span>
              <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">pcm</span>
            </p>
            <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">
              + no up-front fees!
            </span>
            <a
              href="mailto:zak@wardpropertymanagement.co.uk"
              className="mt-10 block w-full rounded-md px-3 py-2 text-center text-sm font-semibold text-white shadow-sm"
              style={{ backgroundColor: '#0c121b' }}
            >
              Contact Us
            </a>
            <p className="mt-6 text-xs leading-5 text-gray-600">
              Invoices and receipts available, discounts available for multiple properties.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
