import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Footer from "../../components/Footer";
import RentWithUsCard from "../../components/RentWithUsCard";
import Stepper from "../../components/WhyRentPageComponents/Stepper";
import WhyRentSection from "../../components/WhyRentPageComponents/WhyRentSection";
import { Box, Container } from "@mui/material";
import FadeInUp from "../../components/FadeInUp";
import { Helmet } from "react-helmet-async";

function Rentyourproperty({ title }) {
  return (
    <Box>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <RentWithUsCard></RentWithUsCard>
      <Container>
        <FadeInUp>
          <WhyRentSection></WhyRentSection>
        </FadeInUp>
        <FadeInUp>
          <Stepper></Stepper>
        </FadeInUp>
      </Container>
      <Footer></Footer>

    </Box>

  );
}

export default Rentyourproperty;
