import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import whyChooseUsItems from '../../assets/whyChooseUsItems';
import WhyChooseUsCard from './WhyChooseUsCard';
import FadeInUp from '../FadeInUp';

function WhyChooseUs() {
  return (
    <Container sx={{
      paddingY: '1rem'
    }}>
      <FadeInUp>
      <Typography 
        variant='h4'
        sx={{
          textAlign: 'center',
          textTransform: 'uppercase',
          marginBottom: '1rem'
        }}
      >
        Why choose us
      </Typography>
      <Box 
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: { xs: 'center', md: 'space-between' }, // Center on mobile, space-between on desktop
          alignItems: { xs: 'center', md: 'flex-start' }, // Center items on mobile
        }}
      >
        {
          whyChooseUsItems.map((item) => (
            <WhyChooseUsCard 
              key={item.id} 
              title={item.title} 
              description={item.description} 
              backgroundImage={item.backgroundImage} 
              logo={item.logo} 
              link={item.link} 
            />
          ))
        }
      </Box>
      </FadeInUp>
    </Container>
  )
}

export default WhyChooseUs;
