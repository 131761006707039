import React from "react";
import { Box, Container, Typography } from "@mui/material";
import FlipCard from "./FlipCard";

function WhyRentSection() {
  return (
    <Box sx={{ marginBottom: "3rem" }}>
      <Container
        sx={{
          paddingY: "1rem",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            textAlign: "center",
            textTransform: "uppercase",
            marginBottom: "1rem",
          }}
        >
          Why choose us
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: { xs: "center", md: "space-between" }, // Center on mobile, space-between on desktop
            alignItems: { xs: "center", md: "flex-start" }, // Center items on mobile
          }}
        >
          <FlipCard></FlipCard>
        </Box>
        <Box
          sx={{
            width: { xs: "100%", md: "50%" },
            display: "flex",
            justifyContent: "center",
            paddingLeft: { md: 0, lg: "6rem" },
            paddingTop: { xs: "2rem", md: 0 },
          }}
        ></Box>
      </Container>
      <Container>
        <Typography
          variant="h2"
          sx={{
            fontSize: "clamp(1.8rem, 1.3654rem + 0.9065vw, 2rem)",
            fontWeight: "bold",
            textTransform: "uppercase",
            marginBottom: "1rem",
            textAlign: "center",
          }}
        >
          The Process
        </Typography>
        <Typography>
          Ward Property Management delivers specialised services in residential
          property management and rentals. Our commitment and expertise through
          lease management, tenant screening and maintenance provides peace of
          mind whilst maximising your properties potential. Let us take care of
          your property and enjoy more of the free time you deserve. Your
          trusted property management partner.
        </Typography>
      </Container>
    </Box>
  );
}

export default WhyRentSection;
