import React from "react";
import { Container, Divider, Box, Typography, Button } from "@mui/material";
import ServiceTicketCard from "../components/ServiceTicketCard";
import CreateTicket from "../components/CreateTicket";
import Footer from "../components/Footer";
import FetchTicket from "../components/FetchTicket";
import FadeInUp from "../components/FadeInUp";
import { Helmet } from "react-helmet-async";

function ServiceTicket({ title }) {
  return (
    <Box>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <ServiceTicketCard></ServiceTicketCard>
      <Container></Container>
      <div className="App">
        <FadeInUp>
          <Typography
            variant="h4"
            sx={{
              textAlign: "center",
              marginBottom: "1rem",
            }}
          >
            Submit a Maintenance Request
          </Typography>
        </FadeInUp>
      </div>
      <Box sx={{ marginBottom: "3rem" }}>
        <Container>
          <Box sx={{ marginBottom: "3rem" }}>
            <Container>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  alignItems: "flex-start", // Align both sides at the top
                  gap: { xs: "2rem", md: "3rem" }, // Gap between the columns (adjust as needed)
                }}
              >
                <Box
                  sx={{
                    width: { xs: "100%", md: "50%" },
                    display: "flex",
                    flexDirection: "column",
                    paddingRight: { xs: 0, md: "1rem" },
                  }}
                >
                  {" "}
                  <FadeInUp>
                    <Typography
                      variant="h4"
                      sx={{
                        fontSize: "clamp(1.2rem, 1.3654rem + 0.9065vw, 1.5rem)",
                        marginBottom: "0.5rem", // Tighten margin below the header
                      }}
                    >
                      Create a new request
                    </Typography>
                    <Divider></Divider>
                    <Typography sx={{ marginBottom: "0.5rem" }}>
                      {" "}
                      {/* Reduced space */}
                      If you have an issue with a current property you are
                      renting then please submit a request below and we will make
                      sure to respond within the hour to address the issue. For
                      more serious issues please reach out directly to one of
                      the team.
                    </Typography>
                  </FadeInUp>
                  <Box
                    sx={{
                      marginTop: 0, // No top margin
                      padding: 0, // No padding to reduce vertical space
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <CreateTicket />
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: { xs: "100%", md: "50%" },
                    display: "flex",
                    flexDirection: "column",
                    paddingRight: { xs: 0, md: "1rem" },
                  }}
                >
                  <FadeInUp>
                    <Typography
                      variant="h4"
                      sx={{
                        fontSize: "clamp(1.2rem, 1.3654rem + 0.9065vw, 1.5rem)",
                        marginBottom: "0.5rem", // Tighten margin below the header
                      }}
                    >
                      Fetch an existing request
                    </Typography>
                    <Divider></Divider>
                    <Typography sx={{ marginBottom: "5.0rem" }}>
                      {" "}
                      {/* Reduced space */}
                      Already have an existing issue? Enter your request below.
                    </Typography>
                  </FadeInUp>
                  <Box
                    sx={{
                      marginTop: 0, // No top margin
                      padding: 0, // No padding to reduce vertical space
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <FetchTicket />
                  </Box>
                </Box>
              </Box>
            </Container>
          </Box>
        </Container>
      </Box>
      <Footer></Footer>
    </Box>
  );
}

export default ServiceTicket;
