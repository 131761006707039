import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Modal,
  Typography,
  Grid,
  Alert,
  Snackbar,
  IconButton,
  Divider,
  CircularProgress
} from '@mui/material';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PropertyIcons from "../../Misc/PropertyIcons";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Carousel styles
import VisibilityIcon from '@mui/icons-material/Visibility';
function RentSaleItem(props) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [loading, setLoading] = useState(false); // State to manage loading

  const handleDelete = () => {
    setOpen2(true); // Open the dialog box
  };

  const handleClose = () => {
    setOpen2(false); // Close the dialog box without proceeding
  };
  const handleConfirmDelete = () => {
    setLoading(true); // Set loading state to true
    props.handleDeleteItem(itemUrluuid, itemAddress); // Call the onDelete function from the parent component
    setLoading(false); // Reset loading state after calling delete
  };

  const [formData, setFormData] = useState({
    title: props.itemTitle,
    address: props.itemAddress,
    price: Math.floor(props.itemPrice),
    deposit: props.deposit,
    bedrooms: props.itemBedrooms,
    bathrooms: props.itemBathrooms,
    livingrooms: props.itemLivingrooms,
    furnishing: props.furnishing,
    style: props.style,
    status: props.status,
    heatingType: props.heatingType,
    energyRating: props.energyRating,
    town: props.town,
    leaseLength: props.leaseLength,
    urluuid: props.itemUrluuid,
    postcode: props.postcode,
    county: props.county
  });
  
  const {
    itemImg,
    distance,
    distanceSet,
    itemUrluuid,
    dateAdded,
    handleDeleteItem,
    handleEditItem
    } = props;

    const [itemAddress, setAddress] = useState(props.itemAddress);
    const [itemPrice, setPrice] = useState(props.itemPrice);
    const [itemShortDescription, setShortDescription] = useState(props.itemShortDescription);
    const [itemBedrooms, setBedrooms] = useState(props.itemBedrooms);
    const [itemBathrooms, setBathrooms] = useState(props.itemBathrooms);
    const [itemLivingrooms, setLivingrooms] = useState(props.itemLivingrooms);
    const [town, setTown] = useState(props.town);
    const [postcode, setPostcode] = useState(props.postcode);
    const [itemTitle, setItemTitle] = useState(props.itemTitle);
    const [county, setCounty] = useState(props.county);
    const [views, setViews] = useState(props.views);




    
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [showDistance, setShowDistance] = useState(false);
  const [openModal, setOpenModal] = useState(false); // Modal control state
  const [openStats, setOpenStats] = useState(false); // Modal control state

  useEffect(() => {
    if (distanceSet) {
      const timer = setTimeout(() => {
        setShowDistance(true);
      }, 1000);

      return () => clearTimeout(timer);
    } else {
      setShowDistance(false);
    }
  }, [distanceSet]);

  const [bannerText, setBannerText] = useState(null);
  const [showBanner, setShowBanner] = useState(false);

  function isWithinLast24Hours(dateString) {
    const currentTime = new Date();
    const itemDate = new Date(dateString);
    itemDate.setHours(itemDate.getHours() - 1);

    const timeDifference = currentTime - itemDate;
    return timeDifference;
  }

  const banners = [
    {
      condition:
        isWithinLast24Hours(dateAdded) <= 24 * 60 * 60 * 1000 &&
        isWithinLast24Hours(dateAdded) >= 60 * 60 * 1000,
      text:
        "Recently Added - " +
        Math.round(isWithinLast24Hours(dateAdded) / 3600000) +
        " hour" +
        (Math.round(isWithinLast24Hours(dateAdded) / 3600000) !== 1
          ? "s"
          : "") +
        " ago",
    },
    {
      condition: isWithinLast24Hours(dateAdded) < 60 * 60 * 1000,
      text:
        "Recently Added - " +
        Math.round(isWithinLast24Hours(dateAdded) / 60000) +
        " minute" +
        (Math.round(isWithinLast24Hours(dateAdded) / 60000) !== 1 ? "s" : "") +
        " ago",
    },
    {
      condition: itemPrice > 1000,
      text: "Premium Property",
    },
    {
      condition: itemBedrooms >= 2,
      text: "Spacious 4+ Bedrooms",
    },
    {
      condition: itemBathrooms >= 1,
      text: "Luxury Bathrooms",
    },
    {
      condition: itemPrice > 500,
      text: "Excellent Price",
    },
  ];

  useEffect(() => {
    const matchingBanner = banners.find((banner) => banner.condition);
    if (matchingBanner) {
      setBannerText(matchingBanner.text);
      const timer = setTimeout(() => setShowBanner(true), 1500);
      return () => clearTimeout(timer);
    }
  }, [itemPrice, itemBedrooms, itemBathrooms]);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleOpenStats = () => setOpenStats(true);
  const handleCloseStats = () => setOpenStats(false);

  const handleConfirmEdit = (event) => {
    setLoading(true); // Set loading state to true
    setAddress(formData.address);
    setPrice(formData.price);
    //setShortDescription(formData.shortD)
    setBedrooms(formData.bedrooms);
    setBathrooms(formData.bathrooms);
    setLivingrooms(formData.livingrooms);
    setTown(formData.town);
    setPostcode(formData.postcode);
    setItemTitle(formData.title);
    setCounty(formData.county);
    event.preventDefault();
    props.handleEditItem(formData);
    setLoading(false);
    handleCloseModal(); // Reset loading state after calling delete
  };

  return (
    <>
      <Box
        sx={{
          position: "relative",
          marginTop: "2rem",
          marginBottom: "2rem",
          borderRadius: 2,
          boxShadow: 2,
          maxHeight: { md: "380px" },
          overflow: "hidden",
          transition: "transform 0.2s, background-color 0.2s",
          "&:hover": {
            transform: { md: "scale(1.02)" },
            backgroundColor: "#f5f5f5",
          },
        }}
      >
        <Link
          to={`/property/${itemUrluuid}`}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "stretch",
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", md: "50%" },
                padding: 2,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  fontSize: { xs: "1.2em", md: "1.4em" },
                  fontWeight: "bold",
                  color: "black",
                  textTransform: "capitalize",
                  marginBottom: "0.5rem",
                }}
              >
                {itemTitle}
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  fontSize: { xs: "1.1em", md: "1.3em" },
                  textTransform: "uppercase",
                  marginTop: "0.3rem",
                }}
              >
                {itemAddress}, {town}, {county}, {postcode}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  marginY: "1rem",
                  fontSize: { xs: "0.9em", md: "1em" },
                }}
              >
                {itemShortDescription}
              </Typography>
              <PropertyIcons
                bedrooms={itemBedrooms}
                bathrooms={itemBathrooms}
                livingrooms={itemLivingrooms}
              />
              <Typography
                variant="h6"
                sx={{
                  textTransform: "uppercase",
                  color: "#0c121b",
                  textWeight: "bold",
                  marginTop: "0.5rem",
                  fontWeight: "bold",
                }}
              >
                £{Math.floor(itemPrice)} per month
              </Typography>

            </Box>

            <Box
              sx={{
                width: { xs: "100%", md: "50%" },
                display: "flex",
                height: { xs: "auto", md: "100%" },
                minHeight: { xs: "230px", md: "auto" },
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
                position: "relative",
              }}
            >
              <Carousel
                showThumbs={false}
                showStatus={false}
                showArrows={false}
                infiniteLoop
                useKeyboardArrows
                autoPlay
                dynamicHeight
              >
                {itemImg.map((img, index) => (
                  <div key={index}>
                    <img
                      src={img}
                      alt={`property img ${index + 1}`}
                      style={{ maxHeight: "380px", objectFit: "cover" }}
                    />
                  </div>
                ))}
              </Carousel>
            </Box>
          </Box>
          <Divider />
        </Link>

        {/* Edit and Delete Buttons */}
        <Box
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
          }}
        >
          <IconButton
            sx={{
              backgroundColor: "#fff",
              border: "2px solid #ccc",
              borderRadius: "50%",
              padding: "0.5rem",
              "&:hover": {
                backgroundColor: "#f0f0f0",
              },
            }}
            onClick={handleOpenModal} // Opens the modal on click
          >
            <EditIcon sx={{ color: "#0c121b" }} />
          </IconButton>

          <IconButton
            sx={{
              backgroundColor: "#fff",
              border: "2px solid #ccc",
              borderRadius: "50%",
              padding: "0.5rem",
              "&:hover": {
                backgroundColor: "#f0f0f0",
              },
            }}
            onClick={handleOpenStats} // Opens the modal on click
          >
            <VisibilityIcon sx={{ color: "#0c121b" }} />
          </IconButton>

          <IconButton
            sx={{
              backgroundColor: "#fff",
              border: "2px solid #ccc",
              borderRadius: "50%",
              padding: "0.5rem",
              "&:hover": {
                backgroundColor: "#f0f0f0",
              },
            }}
            onClick={handleDelete}
          >
            <DeleteIcon sx={{ color: "#0c121b" }} />
          </IconButton>
        </Box>

        <Dialog
      open={openModal}
      onClose={handleCloseModal}
      fullWidth={true}
      maxWidth={false}
      PaperProps={{
        sx: {
          maxWidth: "100%",
          margin: "auto",
          transform: "translateY(0)",
        },
      }}
    >
      <DialogContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '500px' }}>
        {loading ? (
          <CircularProgress />
        ) : (
          <form onSubmit={handleConfirmEdit}>
            <DialogTitle>Edit Details</DialogTitle>
            <Grid container spacing={2} sx={{ paddingTop: "5px" }}>
              {/* Your form fields here */}
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  name="title"
                  label="Title"
                  fullWidth
                  required
                  value={formData.title}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  name="address"
                  label="Address"
                  fullWidth
                  required
                  value={formData.address}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  name="price"
                  label="Price pcm (£)"
                  fullWidth
                  required
                  type="number"
                  value={formData.price}
                  onChange={handleInputChange}
                />
              </Grid>

              {/* Second Row */}
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  name="deposit"
                  label="Deposit (£)"
                  required
                  fullWidth
                  type="number"
                  value={formData.deposit}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth>
                  <InputLabel id="bedrooms-label">Bedrooms</InputLabel>
                  <Select
                    labelId="bedrooms-label"
                    name="bedrooms"
                    required
                    value={formData.bedrooms}
                    onChange={handleInputChange}
                    label="Bedrooms"
                  >
                    {[...Array(6)].map((_, index) => (
                      <MenuItem key={index} value={index}>
                        {index}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth>
                  <InputLabel id="bathrooms-label">Bathrooms</InputLabel>
                  <Select
                    labelId="bathrooms-label"
                    name="bathrooms"
                    required
                    value={formData.bathrooms}
                    onChange={handleInputChange}
                    label="Bathrooms"
                  >
                    {[...Array(6)].map((_, index) => (
                      <MenuItem key={index} value={index}>
                        {index}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth>
                  <InputLabel id="livingrooms-label">Living Rooms</InputLabel>
                  <Select
                    labelId="livingrooms-label"
                    name="livingrooms"
                    required
                    value={formData.livingrooms}
                    onChange={handleInputChange}
                    label="Livingrooms"
                  >
                    {[...Array(6)].map((_, index) => (
                      <MenuItem key={index} value={index}>
                        {index}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth>
                  <InputLabel id="furnishing-label">Furnishing</InputLabel>
                  <Select
                    labelId="furnishing-label"
                    name="furnishing"
                    required
                    value={formData.furnishing}
                    onChange={handleInputChange}
                    label="Furnishing"
                  >
                    <MenuItem value="Unfurnished">Unfurnished</MenuItem>
                    <MenuItem value="Furnished">Furnished</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Third Row */}
              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth>
                  <InputLabel id="style-label">Style</InputLabel>
                  <Select
                    labelId="style-label"
                    name="style"
                    required
                    value={formData.style}
                    onChange={handleInputChange}
                    label="Style"
                  >
                    <MenuItem value="Apartment">Apartment</MenuItem>
                    <MenuItem value="Detached House">Detached House</MenuItem>
                    <MenuItem value="Terraced House">Terraced House</MenuItem>
                    <MenuItem value="Semi-Detached House">Semi-Detached House</MenuItem>
                    <MenuItem value="End Terrace House">End Terrace House</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Fourth Row */}
              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth>
                  <InputLabel id="status-label">Status</InputLabel>
                  <Select
                    labelId="status-label"
                    required
                    name="status"
                    value={formData.status}
                    onChange={handleInputChange}
                    label="Status"
                  >
                    <MenuItem value="To Let">To Let</MenuItem>
                    <MenuItem value="Agreed">Agreed</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth>
                  <InputLabel id="heatingtype-label">Heating Type</InputLabel>
                  <Select
                    labelId="heatingtype-label"
                    name="heatingType"
                    required
                    value={formData.heatingType}
                    onChange={handleInputChange}
                    label="Heating Type"
                  >
                    <MenuItem value="Gas">Gas</MenuItem>
                    <MenuItem value="Oil">Oil</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  name="county"
                  label="County"
                  fullWidth
                  required
                  value={formData.county}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  name="postcode"
                  label="Post Code"
                  fullWidth
                  required
                  value={formData.postcode}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  name="energyRating"
                  label="Energy Rating"
                  fullWidth
                  required
                  value={formData.energyRating}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  name="town"
                  label="Town"
                  fullWidth
                  required
                  value={formData.town}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth>
                  <InputLabel id="leaseLength-label">Lease Length</InputLabel>
                  <Select
                    labelId="leaseLength-label"
                    name="leaseLength"
                    required
                    value={formData.leaseLength}
                    onChange={handleInputChange}
                    label="Lease Length"
                  >
                    <MenuItem value="12 Months">12 Months</MenuItem>
                    <MenuItem value="6 Months">6 Months</MenuItem>
                    <MenuItem value="Rolling">Rolling</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            

<Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 2 }}>
              <Button
                variant="contained"
                onClick={handleCloseModal}
                sx={{ mr: 2 }}
                disabled={loading}
                sx={{
                  backgroundColor: '#0c121b',
                  color: '#FFFFFF',
                  '&:hover': {
                    backgroundColor: '#2d394b',
                  },
                  margin: '10px',
                }}
              >
                Close
              </Button>
              <Button
                variant="contained"
                type="submit"
                disabled={loading}
                sx={{
                  backgroundColor: '#0c121b',
                  color: '#FFFFFF',
                  '&:hover': {
                    backgroundColor: '#2d394b',
                  },
                  margin: '10px',
                }}
              >
                {loading ? <CircularProgress size={24} /> : "Submit"}
              </Button>
            </Box>
          </form>
        )}
      </DialogContent>
    </Dialog>

    <Dialog
      open={openStats}
      onClose={handleCloseStats}
      fullWidth={true}
      maxWidth={false}
      PaperProps={{
        sx: {
          maxWidth: "100%",
          margin: "auto",
          transform: "translateY(0)",
        },
      }}
    >
      <DialogContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '500px' }}>
        {loading ? (
          <CircularProgress />
        ) : (
        
<Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 2 }}>
              <Button
                variant="contained"
                onClick={handleCloseStats}
                sx={{ mr: 2 }}
                disabled={loading}
                sx={{
                  backgroundColor: '#0c121b',
                  color: '#FFFFFF',
                  '&:hover': {
                    backgroundColor: '#2d394b',
                  },
                  margin: '10px',
                }}
              >
                Close
              </Button>
              <Button
                variant="contained"
                type="submit"
                disabled={loading}
                sx={{
                  backgroundColor: '#0c121b',
                  color: '#FFFFFF',
                  '&:hover': {
                    backgroundColor: '#2d394b',
                  },
                  margin: '10px',
                }}
              >
                {loading ? <CircularProgress size={24} /> : "Submit"}
              </Button>
            </Box>
        )}
      </DialogContent>
    </Dialog>



    <Modal open={open2} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #ccc",
            boxShadow: 24,
            p: 4,
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {loading ? (
            <CircularProgress /> // Spinner displayed during loading
          ) : (
            <>
              <Typography variant="h6" component="h2" mb={2}>
                Confirm Deletion
              </Typography>
              <Typography mb={3}>
                Are you sure you want to delete this item? This action cannot be undone.
              </Typography>
              <Box display="flex" justifyContent="flex-end">
                <Button onClick={handleClose}             sx={{
              backgroundColor: '#0c121b',
              color: '#FFFFFF',
              '&:hover': {
                backgroundColor: '#2d394b',
              },
              margin: '10px',
            }}>
                  Cancel
                </Button>
                <Button
                  onClick={handleConfirmDelete}
                  color="error"
                  sx={{
                    backgroundColor: '#cb4444',
                    color: '#FFFFFF',
                    '&:hover': {
                      backgroundColor: '#b31414',
                    },
                    margin: '10px',
                  }}
                >
                  Yes, Delete
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Modal>


      </Box>
    </>
  );
}

export default RentSaleItem;
