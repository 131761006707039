import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Box, Typography, Divider } from "@mui/material";
import PropertyIcons from "../Misc/PropertyIcons";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Carousel styles

function RentSaleItem(props) {
  const {
    itemTitle,
    itemAddress,
    itemPrice,
    itemShortDescription,
    itemBedrooms,
    itemBathrooms,
    itemLivingrooms, // itemImg should now be an array of image URLs
    itemUrluuid,
    itemImg,
    dateAdded,
    distance,
    distanceSet,
    town,
    postcode,
    status,
    county,
    viewableFrom,
    views,
  } = props;

  const [showDistance, setShowDistance] = useState(false);

  useEffect(() => {
    if (distanceSet) {
      const timer = setTimeout(() => {
        setShowDistance(true);
        console.log(distanceSet);
      }, 1000); // Wait for 1 second before showing the distance

      return () => clearTimeout(timer); // Clean up the timer on unmount
    } else {
      setShowDistance(false);
      console.log(distanceSet); // Hide the banner if distanceSet is false
    }
  }, [distanceSet]);

  const [bannerText, setBannerText] = useState(null);
  const [showBanner, setShowBanner] = useState(false);

  function isWithinLast24Hours(dateString) {
    const currentTime = new Date();
    const itemDate = new Date(dateString);
    itemDate.setHours(itemDate.getHours() - 1);

    const timeDifference = currentTime - itemDate;
    return timeDifference;
  }

  const banners = [
    {
      condition:
        isWithinLast24Hours(dateAdded) <= 24 * 60 * 60 * 1000 &&
        isWithinLast24Hours(dateAdded) >= 60 * 60 * 1000,
      text:
        "Recently Added - " +
        Math.round(isWithinLast24Hours(dateAdded) / 3600000) +
        " hour" +
        (Math.round(isWithinLast24Hours(dateAdded) / 3600000) !== 1
          ? "s"
          : "") +
        " ago",
    },
    {
      condition: isWithinLast24Hours(dateAdded) < 60 * 60 * 1000,
      text:
        "Recently Added - " +
        Math.round(isWithinLast24Hours(dateAdded) / 60000) +
        " minute" +
        (Math.round(isWithinLast24Hours(dateAdded) / 60000) !== 1 ? "s" : "") +
        " ago",
    },
    {
      condition: itemPrice > 750 && itemPrice <=1000,
      text: "Premium Property",
    },
    {
      condition: itemBedrooms >= 4,
      text: "Spacious 4+ Bedrooms",
    },
    {
      condition: itemBathrooms > 1,
      text: "Luxury Bathrooms",
    },
    {
      condition: itemPrice < 1250,
      text: "Excellent Price",
    },
  ];

  useEffect(() => {
    const matchingBanner = banners.find((banner) => banner.condition);
    if (matchingBanner) {
      setBannerText(matchingBanner.text);
      const timer = setTimeout(() => setShowBanner(true), 1500);
      return () => clearTimeout(timer);
    }
  }, [itemPrice, itemBedrooms, itemBathrooms]);

  return (
    <Link to={`/property/${itemUrluuid}`} style={{ textDecoration: "none" }}>
      <Box
        sx={{
          marginTop: "2rem",
          marginBottom: "2rem",
          borderRadius: 2,
          boxShadow: 2,
          maxHeight: { md: "380px" },
          overflow: "hidden",
          transition: "transform 0.2s, background-color 0.2s",
          "&:hover": {
            transform: { md: "scale(1.02)" },
            backgroundColor: "#f5f5f5",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "stretch",
          }}
        >
          {/* Content Section */}
          <Box
            sx={{
              width: { xs: "100%", md: "50%" },
              padding: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: "1.2em", md: "1.4em" },
                fontWeight: "bold",
                color: "black",
                textTransform: "capitalize",
                marginBottom: "0.5rem",
              }}
            >
              {itemTitle}
            </Typography>
            <Typography
              variant="h3"
              sx={{
                fontSize: { xs: "1.1em", md: "1.3em" },
                textTransform: "uppercase",
                marginTop: "0.3rem",
              }}
            >
              {itemAddress}, {town}, {postcode}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                marginY: "1rem",
                fontSize: { xs: "0.9em", md: "1em" },
              }}
            >
              {itemShortDescription}
            </Typography>
            <PropertyIcons
              bedrooms={itemBedrooms}
              bathrooms={itemBathrooms}
              livingrooms={itemLivingrooms}
            />

            {status == "Agreed" ? (            
            
            <Typography
              variant="h5"
              sx={{
                textTransform: "uppercase",
                color: "#0c121b",
                marginTop: "0.5rem",
                fontSize: 25,
                fontWeight: "bold"
              }}
            >
              Let Agreed
            </Typography>) : (
            <Typography
              variant="h6"
              sx={{
                textTransform: "uppercase",
                color: "#0c121b",
                textWeight: "bold",
                marginTop: "0.5rem",
                fontWeight: "bold",
              }}
            >
              £{Math.floor(itemPrice)} per month
            </Typography>
            )}
          </Box>


          {/* Carousel Section */}
          <Box
            sx={{
              width: { xs: "100%", md: "50%" },
              display: "flex",
              height: { xs: "auto", md: "100%" },
              minHeight: { xs: "230px", md: "auto" },
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <Carousel
              showThumbs={false}
              showStatus={false}
              showArrows={false}
              infiniteLoop
              useKeyboardArrows
              autoPlay
              dynamicHeight
            >
              {itemImg.map((img, index) => (
                <div key={index}>
                  <img
                    src={img}
                    alt={`property img ${index + 1}`}
                    style={{ maxHeight: "380px", objectFit: "cover" }}
                  />
                </div>
              ))}
            </Carousel>

            {/* Banner overlay */}
            <Box
              sx={{
                position: "absolute",
                top: { md: "10px", xs: "15px" },
                left: "10px",
                backgroundColor: "#0c121b",
                color: "#FFFFFF",
                padding: "0.5rem 1rem",
                borderRadius: "8px",
                fontSize: "0.9rem",
                zIndex: 2,
                opacity: showBanner ? 0.8 : 0,
                transition: "opacity 1s ease-in-out",
              }}
            >
              {bannerText}
            </Box>

            {/* Bottom distance banner */}
            <Box
              sx={{
                position: "absolute",
                bottom: "10px",
                left: "10px",
                backgroundColor: "#0c121b",
                color: "#FFFFFF",
                padding: "0.5rem 1rem",
                borderRadius: "8px",
                fontSize: "0.9rem",
                zIndex: 2,
                opacity: showDistance ? 0.8 : 0,
                transition: "opacity 1s ease-in-out",
              }}
            >
              <span className="text-opacity-100">
                {parseInt(distance, 10)} miles away
              </span>
            </Box>
          </Box>
        </Box>
        <Divider />
      </Box>
    </Link>
  );
}

export default RentSaleItem;
