import React, { useState } from "react";
import { Grid, Card, CardContent, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { AutoAwesome, Handyman, ManageAccounts } from '@mui/icons-material'; // Import more icons as needed

const FlipCardContainer = styled('div')({
  perspective: '1000px',
  cursor: 'pointer',
  height: '300px', // Adjust height as needed
});

const FlipCardInner = styled('div')(({ flipped }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  transformStyle: 'preserve-3d',
  transition: 'transform 0.6s',
  transform: flipped ? 'rotateY(180deg)' : 'rotateY(0deg)',
}));

const CardFace = styled(Card)({
  position: 'absolute',
  width: '100%',
  height: '100%',
  backfaceVisibility: 'hidden',
  display: 'flex',
  flexDirection: 'column', // Stack content vertically
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#0c121b', // Dark blue background
  color: '#fff', // White text color for contrast
});

const CardFront = styled(CardFace)(({ flipped }) => ({
  zIndex: flipped ? 1 : 2,
  color: flipped ? '#0c121b' : '#fff', // Match card color when flipped
  transition: 'color 0.6s',
}));

const CardBack = styled(CardFace)({
  transform: 'rotateY(180deg)',
  zIndex: 2,
});

const IconWrapper = styled('div')({
  marginBottom: '16px', // Space between icon and title
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const FlipCard = ({ title, description, moreInfo, icon: IconComponent }) => {
  const [flipped, setFlipped] = useState(false);

  return (
    <Grid item xs={12} sm={6} md={4} onClick={() => setFlipped(!flipped)}>
      <FlipCardContainer>
        <FlipCardInner flipped={flipped}>
          <CardFront flipped={flipped}>
            <CardContent>
              <IconWrapper>
                <IconComponent fontSize="large" /> {/* Pass the icon component */}
              </IconWrapper>
              <Typography variant="h5" align="center" gutterBottom>{title}</Typography>
              <Typography align="center">{description}</Typography>
            </CardContent>
          </CardFront>
          <CardBack>
            <CardContent>
              <Typography align="center">{moreInfo}</Typography>
            </CardContent>
          </CardBack>
        </FlipCardInner>
      </FlipCardContainer>
    </Grid>
  );
};

const FlipCardGrid = () => {
  const cards = [
    { title: "Management", description: "", moreInfo: "Our full-service property management solutions are designed to provide peace of mind.", icon: ManageAccounts },
    { title: "Maintenance", description: "", moreInfo: "We offer comprehensive maintenance services to keep your property in pristine condition.", icon: Handyman },  
    { title: "Marketing", description: "", moreInfo: "Our professional marketing services helps position your property for a quick and easy sale through professional photography and videography packages.", icon: AutoAwesome },
  ];
  return (
    <Grid container spacing={2} direction="row" wrap="wrap">
      {cards.map((card, index) => (
        <FlipCard 
          key={index} 
          title={card.title} 
          description={card.description} 
          moreInfo={card.moreInfo} 
          icon={card.icon} // Pass the icon component
        />
      ))}
    </Grid>
  );
};

export default FlipCardGrid;
