import React from 'react';
import { Box } from '@mui/material';
import discoverImg from '../assets/images/discover-img.jpeg';
import DiscoverCard from './DiscoverCard';

function DiscoverSection() {
  return (
    <Box sx={{
        height: '75vh',
        backgroundImage: `url(${discoverImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex'
    }}>
        <DiscoverCard variant='h5' name='Rent with us' description='From finding tenants to managing properties, we have you covered.' buttonTitle='Our properties' buttonLink='/tenants' />
    </Box>
  )
}

export default DiscoverSection;