import React from "react";
import { Box, Typography } from "@mui/material";
import BedOutlinedIcon from "@mui/icons-material/BedOutlined";
import BathtubOutlinedIcon from "@mui/icons-material/BathtubOutlined";
import ChairOutlinedIcon from "@mui/icons-material/ChairOutlined";

function PropertyIcons({ bedrooms, bathrooms, livingrooms, align = "center" }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: align === "center" ? "center" : "flex-start", // Align based on prop
        alignItems: "center", // Center items vertically
        gap: "1rem", // Space between items
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center", // Center icon and text vertically
        }}
      >
        <BedOutlinedIcon />
        <Typography
          variant="body1"
          sx={{
            marginLeft: "4px",
          }}
        >
          {bedrooms}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center", // Center icon and text vertically
        }}
      >
        <BathtubOutlinedIcon
          sx={{
            position: "relative",
            top: "-3px", // Move the icon up by 3 pixels
          }}
        />
        <Typography
          variant="body1"
          sx={{
            marginLeft: "4px",
          }}
        >
          {bathrooms}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center", // Center icon and text vertically
        }}
      >
        <ChairOutlinedIcon />
        <Typography
          variant="body1"
          sx={{
            marginLeft: "4px",
          }}
        >
          {livingrooms}
        </Typography>
      </Box>
    </Box>
  );
}

export default PropertyIcons;
