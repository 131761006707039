import React from "react";
import Carousel from "react-material-ui-carousel";
import {
  Box,
  Typography,
  Button,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import items from "../assets/items";
import Item from "../components/Item";
import { keyframes } from "@emotion/react";

// Define fade-in animation
const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

function HeaderCarousel() {
  // Define a theme with Lexend from Google Fonts
  const theme = createTheme({
    typography: {
      fontFamily: '"Lexend", sans-serif',  // Use Lexend
      h1: {
        color: "#ffffff",
        fontSize: "4rem",
        textShadow: "1px 1px 4px rgba(0, 0, 0, 0.5)",
        animation: `${fadeIn} 1.5s ease-in-out`,
        "@media (max-width:600px)": {
          fontSize: "2.5rem",
        },
        "@media (max-width:900px)": {
          fontSize: "3rem",
        },
      },
      h5: {
        fontWeight: 300,
        color: "#ffffff",
        fontSize: "1.2rem",
        textShadow: "1px 1px 4px rgba(0, 0, 0, 0.5)",
        animation: `${fadeIn} 2s ease-in-out`,
        "@media (max-width:600px)": {
          fontSize: "1rem",
        },
        "@media (max-width:900px)": {
          fontSize: "1.1rem",
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      {/* Add the Google Fonts link to head in your HTML or JSX file */}
      <link
        href="https://fonts.googleapis.com/css2?family=Lexend&display=swap"
        rel="stylesheet"
      />

      <Box sx={{ position: "relative" }}>
        <Carousel interval={7000}>
          {items.map((item, i) => (
            <Item key={i} item={item} />
          ))}
        </Carousel>

        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 2,
            animation: `${fadeIn} 2.5s ease-in-out`,
            color: "#ffffff",
            textAlign: "center",
            padding: 2,
          }}
        >
          {/* Headline */}
          <Typography variant="h1">Ward Property</Typography>

          <Box sx={{ mt: 2, mb: 2 }}>
            <hr
              style={{
                width: "50px",
                border: "1px solid white",
                margin: "auto",
              }}
            />
          </Box>

          {/* Tagline */}
          <Typography variant="h5" sx={{ mt: 1 }}>
            Property Management. Made Simple.
          </Typography>

          {/* Call to Action */}
          <Button
            variant="contained"
            sx={{
              mt: 3,
              px: 4,
              py: 1.5,
              fontSize: "1rem",
              backgroundColor: "#0c121b",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#111c2d",
              },
            }}
            component={Link}
            to="/properties"
          >
            Start Your Search Now
          </Button>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default HeaderCarousel;
