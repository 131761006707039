import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Button,
  Typography,
  Dialog,
  Slide,
  Grid,
  Divider,
  IconButton,
  Snackbar,
  Alert,
} from "@mui/material";
import { Link } from "react-router-dom";
import ImageGallerySlider from "../ImageGallerySlider";
import PropertyIcons from "../Misc/PropertyIcons";
import Tooltip from "@mui/material/Tooltip";
import PropertyMap from "./PropertyMap"; // Import the PropertyMap component
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg"; // Import the phone icon
import LocationOnIcon from "@mui/icons-material/LocationOn"; // Import the location icon
import FadeInUp from "../FadeInUp";
import SavingsIcon from "@mui/icons-material/Savings";
import GasMeterIcon from "@mui/icons-material/GasMeter";
import ExplicitIcon from "@mui/icons-material/Explicit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import EventSeatIcon from "@mui/icons-material/EventSeat";
import AssignmentIcon from "@mui/icons-material/Assignment";
import HouseIcon from "@mui/icons-material/House";
import Announcement from "@mui/icons-material/Announcement";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import MapIcon from "@mui/icons-material/Map";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import FloatingButton from "../FloatingButton";

function RentSaleProperty(props) {

  const [openMapModal, setOpenMapModal] = useState(false);
  const [openPlanModal, setOpenPlanModal] = useState(false);
  const [currentFloorPlan, setCurrentFloorPlan] = useState(null);
  const {
    propertyAddress,
    propertyPrice,
    propertyLongDescription,
    propertyImages,
    propertyBedrooms,
    propertyBathrooms,
    propertyLivingrooms,
    propertyMapCoords,
    mainDescription,
    status,
    style,
    viewableFrom,
    leaseLength,
    deposit,
    energyRating,
    heatingType,
    furnishing,
    features,
    floorPlan,
    floorPlan2,
    town,
    postcode,
    county,
    up,
    down,
    views,
  } = props;

  // Initialize currentFloorPlan with the first image
  React.useEffect(() => {
    setCurrentFloorPlan(floorPlan);
  }, [floorPlan]);

  const handleOpenMapModal = () => setOpenMapModal(true);
  const handleCloseMapModal = () => setOpenMapModal(false);

  const handleOpenPlanModal = () => setOpenPlanModal(true);
  const handleClosePlanModal = () => setOpenPlanModal(false);

  const [open, setOpen] = useState(false);

  const handleAlertOpen = () => {
    setTimeout(() => {
      setOpen(true);
    }, 3000); // 3000 milliseconds = 3 seconds
  };  

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleImageToggle = () => {
    setCurrentFloorPlan(
      currentFloorPlan === floorPlan ? floorPlan2 : floorPlan
    );
  };

  useEffect(() => {
    handleAlertOpen();
  }, [props]);

  const recipient = "zak@wardpropertymanagement.co.uk,ryan@wardpropertymanagement.co.uk";
  const subject = encodeURIComponent(`Inquiry about ${propertyAddress}`);
  const body = encodeURIComponent(
    `Hello, I would like to inquire about ${propertyAddress} listed on your website. Thank you!`
  );
  const mailtoLink = `mailto:${recipient}?subject=${subject}&body=${body}`;

  // Convert mainDescription text with "\n" into multiple lines
  const mainDescription2 = mainDescription.replace(/\\n/g, "\n");
  const formattedDescription = mainDescription2
    ? mainDescription2.split("\n")
    : [];

  const formattedBedrooms = `${propertyBedrooms} Bedroom${
    propertyBedrooms > 1 ? "s" : ""
  }`;
  const formattedBathrooms = `${propertyBathrooms} Bathroom${
    propertyBathrooms > 1 ? "s" : ""
  }`;
  const formattedLivingrooms = `${propertyLivingrooms} Living Room${
    propertyLivingrooms > 1 ? "s" : ""
  }`;

  // Details to display in the box
  const details = [
    { label: "Status", value: status, icon: <Announcement /> },
    { label: "Style", value: style, icon: <HouseIcon /> },
    { label: "Viewable From", value: viewableFrom, icon: <VisibilityIcon /> },
    { label: "Lease Length", value: leaseLength, icon: <AssignmentIcon /> },
    { label: "Deposit", value: "£" + deposit, icon: <SavingsIcon /> },
    { label: "Energy Rating", value: energyRating, icon: <ExplicitIcon /> },
    { label: "Heating Type", value: heatingType, icon: <GasMeterIcon /> },
    { label: "Furnishing", value: furnishing, icon: <EventSeatIcon /> }, // Using HouseIcon for Furnishing as well
  ];

  return (
    <Box sx={{ backgroundColor: "#f5f5f5", overflow: "hidden", width: "100%", paddingY: "80px"}}>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleAlertClose}>
        <Alert
          onClose={handleAlertClose}
          icon={
            <VisibilityIcon
              style={{ marginRight: "8px", color: "#ffffff" }} // Adjust style if needed
            />
          }
          sx={{
            backgroundColor: "rgba(12, 18, 27, 0.75)", // Grey color with 75% opacity
            color: "#ffffff", // Change text color to white
            "& .MuiSvgIcon-root": {
              color: "#ffffff", // Ensure the icon is also white
            },
          }}
        >
          {views} views since listed.
        </Alert>
      </Snackbar>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          flexWrap: "wrap",
          padding: { md: "2rem" },
          backgroundColor: "#f5f5f5",
          borderRadius: "8px",
          maxWidth: "100%", // Ensure no overflow
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            flex: 1,
            minWidth: { xs: "100%", md: "95%" },
            padding: { xs: 0 },
            borderRadius: "8px",
            overflow: "hidden",
            backgroundColor: "#f5f5f5",
          }}
        >
          <Box
            sx={{
              flex: "0 0 60%", // Fixed width for the image box
              padding: { xs: 0 },
              borderRadius: "8px 0 0 8px",
              overflow: "hidden",
              width: { xs: "100%", md: "60%" }, // Ensure responsive scaling
            }}
          >
            <ImageGallerySlider images={propertyImages} />
          </Box>

          <Box
            sx={{
              flex: "0 0 40%", // Fixed width for the text box
              paddingLeft: { md: "1.5rem" },
              textAlign: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              backgroundColor: "#f5f5f5",
              borderRadius: "0 8px 8px 0",
              padding: "1rem",
              marginTop: { xs: "1rem", md: 0 },
              width: { xs: "100%", md: "40%" }, // Ensure responsive scaling
            }}
          >
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: "1.4em", md: "1.6em" }, // Responsive font size
                fontWeight: "bold",
                textAlign: "center",
                color: "#0c121b",
              }}
            >
              {propertyAddress}
            </Typography>
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: "1.4em", md: "1.6em" }, // Responsive font size
                fontWeight: "bold",
                textAlign: "center",
                color: "#0c121b",
              }}
            >
              {town}, {county}
            </Typography>
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: "1.4em", md: "1.6em" }, // Responsive font size
                fontWeight: "bold",
                textAlign: "center",
                color: "#0c121b",
              }}
            >
              {postcode}
            </Typography>

            {status == "Agreed" ? (            
            
            <Typography
            variant="h3"
            sx={{
              fontSize: { xs: "1.2em", md: "1.4em" }, // Responsive font size
              textTransform: "uppercase",
              color: "#2d394b",
              marginTop: "0.5rem",
              textAlign: "center",
            }}
          >
            Let Agreed
          </Typography>) : (
            <Typography
            variant="h3"
            sx={{
              fontSize: { xs: "1.2em", md: "1.4em" }, // Responsive font size
              textTransform: "uppercase",
              color: "#2d394b",
              marginTop: "0.5rem",
              textAlign: "center",
            }}
          >
            £{Math.floor(propertyPrice)} per month
          </Typography>
            )}

            <Typography
              variant="body1"
              sx={{
                marginY: "1rem",
                textAlign: "center",
                lineHeight: 1.5,
                color: "#555555",
              }}
            >
              {propertyLongDescription}
            </Typography>
            <PropertyIcons
              bedrooms={propertyBedrooms}
              bathrooms={propertyBathrooms}
              livingrooms={propertyLivingrooms}
              align="center"
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginY: "1rem",
                flexWrap: "wrap", // Allow wrapping for smaller screens
              }}
            >
              <Box sx={{ flex: "1 1 30%", margin: "0.5rem" }}>
                {" "}
                {/* Adjusted flex property */}
                <Button
                  variant="outlined"
                  size="large"
                  onClick={handleOpenMapModal}
                  sx={{
                    width: "100%", // Full width for button
                    color: "#0c121b",
                    borderColor: "#0c121b",
                    "&:hover": {
                      backgroundColor: "#0c121b",
                      color: "#FFFFFF",
                    },
                    borderRadius: "20px",
                  }}
                >
                  <LocationOnIcon sx={{ marginRight: "0.5rem" }} /> Location
                </Button>
              </Box>

              <Box sx={{ flex: "1 1 30%", margin: "0.5rem" }}>
                {" "}
                {/* Adjusted flex property for Plan */}
                <Button
                  variant="outlined"
                  size="large"
                  onClick={handleOpenPlanModal}
                  sx={{
                    width: "100%", // Full width for button
                    color: "#0c121b",
                    borderColor: "#0c121b",
                    "&:hover": {
                      backgroundColor: "#0c121b",
                      color: "#FFFFFF",
                    },
                    borderRadius: "20px",
                  }}
                >
                  <MapIcon sx={{ marginRight: "0.5rem" }} /> Plan
                </Button>
              </Box>

              <Box sx={{ flex: "2 1 60%", margin: "0.5rem" }}>
                {" "}
                {/* Wider flex property for Enquire */}
                <Link to={mailtoLink} style={{ width: "100%" }}>
                  {" "}
                  {/* Full width for link */}
                  <Button
                    variant="contained"
                    size="large"
                    sx={{
                      width: "100%", // Full width for button
                      backgroundColor: "#0c121b",
                      color: "#FFFFFF",
                      "&:hover": {
                        backgroundColor: "#2d394b",
                      },
                      borderRadius: "20px",
                    }}
                  >
                    <PermPhoneMsgIcon sx={{ marginRight: "0.5rem" }} /> Enquire
                  </Button>
                </Link>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box>
        <FloatingButton></FloatingButton>
      </Box>

      {/* Title and main description */}
      <FadeInUp>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
            flexDirection: "column", // Stack items in a column if needed
          }}
        >
          <Box
            sx={{
              padding: { xs: "1rem", md: "2rem" }, // Responsive padding
              backgroundColor: "#f5f5f5", // Light background for contrast
              borderRadius: "8px", // Rounded corners
              boxShadow: 2, // Subtle shadow for depth
              border: "0.5px solid #cdcdcd",
              maxWidth: "95%", // Maximum width of the inner box
              width: "100%", // Ensure the inner box takes the full width available
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontSize: { xs: "1.4em", md: "1.5em" }, // Responsive font size
                fontWeight: "bold",
                marginBottom: "1rem",
                color: "#0c121b", // Darker color for better readability
              }}
            >
              Property Details
            </Typography>
            <Box sx={{ marginY: "1rem" }}>
              <Grid container spacing={2}>
                {details.map((item, index) => (
                  <Grid item xs={12} md={3} key={index}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        padding: "0.5rem",
                        borderRadius: "4px",
                        backgroundColor: "#f5f5f5",
                      }}
                    >
                      {item.icon}
                      <Box sx={{ ml: 1 }}>
                        <Typography
                          variant="h6"
                          sx={{ fontWeight: "600", color: "#2d394b" }}
                        >
                          {item.label}
                        </Typography>
                        <Typography variant="body1" sx={{ color: "#555" }}>
                          {item.value}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Divider sx={{ my: 2 }} /> {/* Divider with margin */}
            <Typography
              variant="body1"
              sx={{
                color: "#333",
                lineHeight: 1.6,
                marginTop: "1rem",
                padding: "1rem",
                backgroundColor: "#f5f5f5", // Background color for the description
                borderRadius: "4px", // Rounded corners for description
              }}
            >
              {formattedDescription.map((line, index) => (
                <span key={index}>
                  {line}
                  <br /> {/* Line break after each line */}
                </span>
              ))}
            </Typography>
          </Box>
        </Box>
      </FadeInUp>

      {/* Features List */}
      {features && features.length > 0 && (
        <FadeInUp>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center", // Center horizontally
              alignItems: "center", // Center vertically
              flexDirection: "column",
              marginTop: "2rem", // Stack items in a column if needed
            }}
          >
            <Box
              sx={{
                padding: { xs: "1rem", md: "2rem" }, // Responsive padding
                backgroundColor: "#f5f5f5", // Light background for contrast
                borderRadius: "8px", // Rounded corners
                border: "0.5px solid #cdcdcd",
                boxShadow: 2, // Subtle shadow for depth
                maxWidth: "95%", // Maximum width of the inner box
                width: "100%", // Ensure the inner box takes the full width available
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontSize: { xs: "1.3em", md: "1.4em" }, // Responsive font size
                  fontWeight: "bold",
                  marginBottom: "1rem",
                  color: "#0c121b", // Dark color for title
                }}
              >
                Features
              </Typography>
              <Divider sx={{ my: 2 }} /> {/* Divider with margin */}
              <ul style={{ listStyleType: "disc", paddingLeft: "1.5rem" }}>
                {features.map((feature, index) => (
                  <li
                    key={index}
                    style={{ marginBottom: "0.5rem", color: "#0c121b" }}
                  >
                    {feature}
                  </li>
                ))}
              </ul>
            </Box>
          </Box>
        </FadeInUp>
      )}
      <FadeInUp>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
            flexDirection: "column",
            marginTop: "2rem", // Stack items in a column if needed
            marginBottom: "2rem", // Stack items in a column if needed
          }}
        >
          <Box
            sx={{
              padding: { xs: "1rem", md: "2rem" }, // Responsive padding
              backgroundColor: "#f5f5f5", // Light background for contrast
              borderRadius: "8px", // Rounded corners
              border: "0.5px solid #cdcdcd",
              boxShadow: 2, // Subtle shadow for depth
              maxWidth: "95%", // Maximum width of the inner box
              width: "100%", // Ensure the inner box takes the full width available
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontSize: { xs: "1.3em", md: "1.4em" }, // Responsive font size
                fontWeight: "bold",
                marginBottom: "1rem",
                color: "#0c121b", // Dark color for title
              }}
            >
              Broadband/Mobile Coverage Data
              <Tooltip title="Speeds provided are from Ofcom and are not guaranteed.">
                <IconButton
                  sx={{
                    marginLeft: "0.3rem",
                    padding: 0,
                    fontSize: "0.8rem", // Smaller size for the icon
                    color: "#7d7d7d",
                  }}
                >
                  <HelpOutlineIcon sx={{ fontSize: "1rem" }} />{" "}
                  {/* Smaller icon */}
                </IconButton>
              </Tooltip>
            </Typography>
            <Divider sx={{ my: 2 }} /> {/* Divider with margin */}
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "column" },
                padding: "0.5rem 0", // Padding around the content
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: "#0c121b", // Dark text for emphasis
                  fontWeight: "600", // Bold text for emphasis
                }}
              >
                Broadband Speeds (Max)
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  color: "#0c121b",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ArrowDownwardIcon
                  sx={{
                    color: "green",
                    fontSize: "1rem",
                    marginLeft: "0.3rem",
                  }}
                />
                {down} mbps download
                <ArrowUpwardIcon
                  sx={{
                    color: "red",
                    fontSize: "1rem",
                    marginLeft: "0.3rem",
                  }}
                />
                {up} mbps upload
              </Typography>
            </Box>
          </Box>
        </Box>
      </FadeInUp>

      {/* Map Modal */}
      <Dialog
        open={openMapModal}
        onClose={handleCloseMapModal}
        TransitionComponent={Slide}
        TransitionProps={{ direction: "up" }}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "8px",
            overflow: "hidden",
            width: "800px", // Increased width
            height: "500px", // Increased height
          },
        }}
      >
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden", // Ensure map doesn’t overflow the modal
          }}
        >
          <PropertyMap
            lat={propertyMapCoords.lat}
            lng={propertyMapCoords.lng}
            name={propertyAddress}
          />
        </Box>
      </Dialog>

      {/* Floor Plan Modal */}
      <Dialog
        open={openPlanModal}
        onClose={handleClosePlanModal}
        TransitionComponent={Slide}
        TransitionProps={{ direction: "up" }}
        sx={{ p: 2 }}
      >
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={currentFloorPlan}
            alt="Floor Plan"
            style={{
              maxWidth: "100%",
              maxHeight: "80vh",
              objectFit: "contain",
            }}
          />
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              left: "50%",
              transform: "translateX(-50%)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
              p: 1,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              borderRadius: 1,
            }}
          >
            <IconButton onClick={handleImageToggle} sx={{ color: "#FFFFFF" }}>
              {currentFloorPlan === floorPlan ? (
                <ArrowBack />
              ) : (
                <ArrowForward />
              )}
            </IconButton>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
}

export default RentSaleProperty;
