import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, useMapEvents, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "leaflet-control-geocoder/dist/Control.Geocoder.css";
import "leaflet-control-geocoder/dist/Control.Geocoder.js";

// SearchControl component with Northern Ireland-only geocoder filtering
const SearchControl = ({ onGeocodeResult }) => {
  const map = useMap(); // Access Leaflet map context

  useEffect(() => {
    const geocoder = L.Control.Geocoder.nominatim({
      geocodingQueryParams: {
        viewbox: "-7.001034, 55.038360, -5.349003, 54.433148", // Bounding box for Northern Ireland
        bounded: 1, // Force results to be inside the viewbox
        countrycodes: 'gb', // Restrict results to Great Britain
        limit: 10, // Increase the number of results returned
      },
    });

    const searchControl = L.Control.geocoder({
      geocoder,
      defaultMarkGeocode: false, // Disable default marker
      placeholder: 'Search for a location or postcode', // Placeholder text for the input
    })
      .on("markgeocode", (e) => {
        const { center } = e.geocode;
        onGeocodeResult([center.lat, center.lng]); // Pass lat/lng to parent
        map.setView([center.lat, center.lng], 13); // Set map view to the location
      })
      .addTo(map); // Add geocoder to the map

    // Disable scroll zoom for the map
    map.scrollWheelZoom.disable();

    return () => {
      map.removeControl(searchControl); // Cleanup geocoder when component unmounts
    };
  }, [map, onGeocodeResult]); // Re-run when map or callback changes

  return null; // No UI for this component, just functionality
};

const ClickableMap = ({ onCoordinatesChange }) => {
  const [coordinates, setCoordinates] = useState(null); // To store marker coordinates

  // Custom hook to handle map click events and update coordinates
  const LocationMarker = () => {
    useMapEvents({
      click(e) {
        const { lat, lng } = e.latlng;
        const newCoordinates = [lat, lng];
        setCoordinates(newCoordinates); // Update marker coordinates
        onCoordinatesChange(newCoordinates); // Pass new coordinates to parent
      },
    });
    return null;
  };

  const customIcon = new L.Icon({
    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
    shadowSize: [41, 41],
    shadowAnchor: [12, 41],
  });

  // Handle result from geocode search and update coordinates
  const handleGeocodeResult = (newCoordinates) => {
    setCoordinates(newCoordinates); // Update marker position
    onCoordinatesChange(newCoordinates); // Pass to parent formData
  };

  return (
    <MapContainer center={[54.66, -5.67]} zoom={11} style={{ height: "500px", width: "100%" }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />

      {/* Handle map click to place a marker */}
      <LocationMarker />

      {/* Add search functionality (geocoder) with Northern Ireland filtering */}
      <SearchControl onGeocodeResult={handleGeocodeResult} />

      {/* Display the marker if coordinates are available */}
      {coordinates && <Marker position={coordinates} icon={customIcon} />}
    </MapContainer>
  );
};

export default ClickableMap;
