import React from "react";
import { Button, Typography, Box } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import FadeInUp from "../FadeInUp";

function ManageServiceCardChild(props) {
  return (
    <Box
      sx={{
        height: "100%",
        width: { xs: "100%", md: "50%" },
        display: "flex",
        position: "relative",
        flexDirection: `${props.direction}`,
      }}
    >
      <Box
        sx={{
          height: "100%",
          width: "50%",
          backgroundColor: { xs: "", sm: "rgba(255, 255, 255, 0.25)" },
        }}
      >
        <Card
          sx={{
            borderRadius: 5,
            minWidth: { xs: "200px", sm: "275px" },
            minHeight: 200,
            position: "absolute",
            top: "50%",
            right: "50%",
            transform: "translate(50%, -50%)",
            padding: { xs: "1rem", sm: "2rem" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            backgroundColor: "#0c121b",
          }}
        >
          <CardContent
            sx={{
              padding: 0,
            }}
          >
              <Typography
                variant="h4"
                sx={{
                  textTransform: "capitalize",
                  color: "white",
                  marginBottom: "1rem",
                }}
              >
                {props.name}
              </Typography>
              <Typography
                variant="body1"
                color="white"
                sx={{
                  marginBottom: "1rem",
                }}
              >
                {props.description}
              </Typography>
          </CardContent>
          <CardActions
            sx={{
              padding: 0,
            }}
          ></CardActions>
        </Card>
      </Box>
      <Box
        sx={{
          height: "100%",
          width: "50%",
        }}
      ></Box>
    </Box>
  );
}

export default ManageServiceCardChild;
