const whyChooseUsItems = [
    {
        id: 1,
        title: 'Renting',
        description: "Need a place to rent? We will assist you in finding the ideal rental property, whether it's a cozy apartment or a spacious house.",
        backgroundImage: './assets/images/choose-card-img1.jpg',
        logo: './assets/icons/house-door.svg',
        link: '/rent'
    },
    {
        id: 2,
        title: 'Management',
        description: 'From management to maintenance of your property to staging it for sale, we have you covered from start to finish.',
        backgroundImage: './assets/images/choose-card-img2.jpg',
        logo: './assets/icons/download.svg',
        link: '/management'
    },
    {
        id: 3,
        title: 'Rent with Us',
        description: "Ready to rent your property? We will help you market your home to prospective tenants navigating the process with ease.",
        backgroundImage: './assets/images/choose-card-img3.jpg',
        logo: './assets/icons/landlord-icon.svg',
        link: '/rent'
    }
    
    
];

export default whyChooseUsItems;