import React, { useState, useEffect } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Import the tick icon
import {SyncLoader} from 'react-spinners'; // Import the loading spinner
import "../App.css"; // Ensure this import is present to use the CSS

const CreateTicket = () => {
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [issue, setIssue] = useState("");
  const [ticketNumber, setTicketNumber] = useState("");
  const [successMessage, setSuccessMessage] = useState(""); // State for success message
  const [errors, setErrors] = useState({}); // State for validation errors
  const [loading, setLoading] = useState(false); // State for loading status

  const validateForm = () => {
    let formErrors = {};
    const nameRegex = /^[A-Za-z\s]+$/; // Only letters and spaces
    const phoneRegex = /^\d{11}$/; // Exactly 11 digits
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email validation

    if (!name || !nameRegex.test(name)) {
      formErrors.name = 'Name must contain only text and be present.';
    }
    if (!phone || !phoneRegex.test(phone)) {
      formErrors.phone = 'Phone number must be 11 digits and be present.';
    }
    if (!email || !emailRegex.test(email)) {
      formErrors.email = 'A valid email address must be present.';
    }
    if (!issue) {
      formErrors.issue = 'Issue description must be present.';
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return; // Stop form submission if validation fails
    }

    setLoading(true); // Set loading state to true when starting the request

    try {
      // Create a URLSearchParams object with the request data
      const formData = new URLSearchParams();
      formData.append('name', name);
      formData.append('phone', phone);
      formData.append('email', email);
      formData.append('issue', issue);
  
      // Send the POST request
      const response = await axios.post(
        'https://wardpropertymanagement.co.uk/api/v1/tickets/createTicket.php',
        formData,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );
  
      if (response.status === 200) {
        // Clear the form fields
        setName("");
        setPhone("");
        setEmail("");
        setIssue("");
        setTicketNumber(response.data.TicketID);
        setSuccessMessage(`Ticket created successfully! Your ID is: ${response.data.TicketID}`); // Set success message
      }
    } catch (error) {
      console.error('Error creating ticket', error);
    } finally {
      setLoading(false); // Set loading state to false when request completes
    }
  };

  useEffect(() => {
    let timer;
    if (successMessage) {
      // Set a timer to hide the success message and close the modal after 5 seconds
      timer = setTimeout(() => {
        setSuccessMessage("");
        handleCloseModal(); // Close the modal after 5 seconds
      }, 5000); // 5000 milliseconds = 5 seconds
    }
    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, [successMessage]);

  const handleCloseModal = () => {
    setShowModal(false);
    // Reset state when the modal is closed
    setSuccessMessage("");
    setTicketNumber("");
    setName("");
    setPhone("");
    setEmail("");
    setIssue("");
    setErrors({});
  };

  // Inline CSS for error messages
  const errorStyle = {
    color: 'red',
    fontSize: '0.875rem',
    marginTop: '0.25rem',
  };

  return (
    <div>
      <Button
        onClick={() => setShowModal(true)}
        variant="contained"
        sx={{
          backgroundColor: "#0c121b",
          color: "#FFFFFF",
          "&:hover": {
            backgroundColor: "#2d394b",
          },
        }}
      >
        Create Ticket
      </Button>
      <div className={`modal-overlay ${showModal ? "show" : ""}`}>
        <div className={`modal ${showModal ? "show" : ""}`}>
          {successMessage ? (
            <div className="success-message">
              <CheckCircleIcon style={{ color: 'green', fontSize: '3rem' }} /> {/* Tick icon */}
              <p>{successMessage}</p>
            </div>
          ) : (
            <>
              <h2>Create Ticket</h2>
              <div className="field-group">
                <label htmlFor="name">Name</label>
                <input
                  id="name"
                  type="text"
                  placeholder="Enter your name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                {errors.name && <p style={errorStyle}>{errors.name}</p>}
              </div>
              <div className="field-group">
                <label htmlFor="phone">Phone</label>
                <input
                  id="phone"
                  type="text"
                  placeholder="Enter your phone number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  maxLength="11" // Limit input to 11 characters
                />
                {errors.phone && <p style={errorStyle}>{errors.phone}</p>}
              </div>
              <div className="field-group">
                <label htmlFor="email">Email</label>
                <input
                  id="email"
                  type="email"
                  placeholder="Enter your email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {errors.email && <p style={errorStyle}>{errors.email}</p>}
              </div>
              <div className="field-group">
                <label htmlFor="issue">Issue</label>
                <p className="description">
                  Provide a detailed description of the issue you're facing.
                </p>
                <textarea
                  id="issue"
                  placeholder="Describe your issue here"
                  value={issue}
                  onChange={(e) => setIssue(e.target.value)}
                />
                {errors.issue && <p style={errorStyle}>{errors.issue}</p>}
              </div>
            </>
          )}
          <div className="button-container">
            {loading ? (
              <SyncLoader /> // Show loading spinner when `loading` is true
            ) : (
              !successMessage && (
                <>
                  <Button
                    onClick={handleSubmit}
                    variant="contained"
                    sx={{
                      backgroundColor: "#0c121b",
                      color: "#FFFFFF",
                      "&:hover": {
                        backgroundColor: "#2d394b",
                      },
                    }}
                  >
                    Submit
                  </Button>
                  <Button
                    onClick={handleCloseModal}
                    variant="contained"
                    sx={{
                      backgroundColor: "#0c121b",
                      color: "#FFFFFF",
                      "&:hover": {
                        backgroundColor: "#2d394b",
                      },
                    }}
                  >
                    Close
                  </Button>
                </>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateTicket;
