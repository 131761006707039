import { Typography } from '@mui/material';
import React from 'react';
import RentSaleItem from './PropertyListItems';

function RentItem(props) {
  return (
    <RentSaleItem itemId={props.id} itemTitle={props.title} itemAddress={props.address} itemPrice={props.price} itemShortDescription={props.shortDescription} itemBedrooms={props.bedrooms} itemBathrooms={props.bathrooms} itemLivingrooms={props.livingrooms} itemImg={props.img} itemUrluuid={props.urluuid} mapCoords={props.mapCoords} distance={props.distance} dateAdded={props.dateAdded} distanceSet={props.distanceSet} postcode={props.postcode} town={props.town} county={props.county} viewableFrom={props.viewableFrom} views={props.views}     
    status={props.status}
    deposit={props.deposit}
    style={props.style}
    energyRating={props.energyRating}
    heatingType={props.heatingType}
    leaseLength={props.leaseLength}
    furnishing={props.furnishing}
    handleDeleteItem={props.handleDeleteItem}
    handleEditItem={props.handleEditItem}
    />
  )
}

export default RentItem;