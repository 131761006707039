import React, { useState } from "react";
import {
  Button,
  Box,
  Typography,
  List,
  Fade,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Pricing from "./FullyManagedPricing";
import Pricing2 from "./TenantFindPricing";

const PricingTabs = () => {
  const [activeTab, setActiveTab] = useState("free");
  const [fadeKey, setFadeKey] = useState(0); // Key for fade effect
  const features = [
    "Tenant Sourcing: Marketing, viewings, and tenant vetting.",
    "Rent Collection: Automated and consistent rent collection.",
    "Maintenance & Repairs: Emergency support and repair coordination.",
    "Legal Compliance: Ensuring the property adheres to regulations.",
    "Inspections: Regular inspections with detailed reports.",
    "End-to-End Communication: Handling tenant interactions and concerns.",
    "Tenancy Renewals & Endings: Managing renewals, rent reviews, and exits.",
  ];

  const tenantFindPlanFeatures = [
    "Property Advertisement: Listing of property on major portals for exposure.",
    "Find Tenants: A list of accumulated potential tenants for landlord.",
    "Marketing: We will also take some basic photographs for display on our site.",
    "Assist with Viewings: Assistance with landlord for viewings.",
  
  ];

  const renderPricing = () => {
    if (activeTab === "free") {
      return (
        <Box>
          <Pricing />

          <Typography
            variant="h4"
            align="center"
            gutterBottom
            sx={{ marginTop: "3rem" }}
          >
            Fully Managed Property Plan
          </Typography>
          <Divider></Divider>
          <Typography variant="body1" align="center" gutterBottom>
            A comprehensive service designed for landlords who want a hands-off
            approach to managing their rental properties. This plan covers all
            aspects of property management, from tenant sourcing to ongoing
            maintenance, ensuring a hassle-free experience for property owners.
            Key features include:
          </Typography>
          <List>
            {features.map((feature, index) => (
              <ListItem key={index}>
                <ListItemIcon>
                  <CheckCircleIcon sx={{ color: "#0c121b" }} />
                </ListItemIcon>
                <ListItemText primary={feature} />
              </ListItem>
            ))}
          </List>
        </Box>
      );
    } else {
      return (
        <Box>
          <Pricing2 />

          <Typography
            variant="h4"
            align="center"
            gutterBottom
            sx={{ marginTop: "3rem" }}
          >
            Tenant Find
          </Typography>
          <Divider></Divider>
          <Typography variant="body1" align="center" gutterBottom>
            Our tenant find service is designed to help landlords quickly and
            efficiently find suitable tenants for their rental properties. This
            plan is ideal for landlords who prefer to manage their properties
            independently but need professional assistance in marketing and
            securing reliable tenants.
          </Typography>
          <List>
            {tenantFindPlanFeatures.map((feature, index) => (
              <ListItem key={index}>
                <ListItemIcon>
                  <CheckCircleIcon sx={{ color: "#0c121b" }} />
                </ListItemIcon>
                <ListItemText primary={feature} />
              </ListItem>
            ))}
          </List>
        </Box>
      );
    }
  };

  const handleTabChange = (tab) => {
    setFadeKey((prevKey) => prevKey + 1); // Change key to trigger fade
    setActiveTab(tab);
  };

  return (
    <Box sx={{ textAlign: "center", padding: 2 }}>
      <Box sx={{ display: "flex", justifyContent: "center", marginBottom: 2 }}>
        <Button
          variant="contained"
          onClick={() => handleTabChange("free")}
          sx={{
            marginRight: "5px",
            backgroundColor: activeTab === "free" ? "#0c121b" : "transparent",
            color: activeTab === "free" ? "#FFFFFF" : "#000000",
            "&:hover": {
              backgroundColor: activeTab === "free" ? "#2d394b" : "#e0e0e0",
            },
            border:
              activeTab !== "free" ? "1px solid rgba(0, 0, 0, 0.23)" : "none",
          }}
        >
          Fully Managed
        </Button>
        <Button
          variant="contained"
          onClick={() => handleTabChange("paid")}
          sx={{
            backgroundColor: activeTab === "paid" ? "#0c121b" : "transparent",
            color: activeTab === "paid" ? "#FFFFFF" : "#000000",
            "&:hover": {
              backgroundColor: activeTab === "paid" ? "#2d394b" : "#e0e0e0",
            },
          }}
        >
          Tenant Find
        </Button>
      </Box>
      <Fade in key={fadeKey} timeout={1000}>
        <Box>{renderPricing()}</Box>
      </Fade>
    </Box>
  );
};

export default PricingTabs;
