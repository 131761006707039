import React from "react";
import { Link } from "react-router-dom";
import { Box, Button, Container, Typography } from "@mui/material";
import AboutImg from "../../assets/images/about-img.png";

function AboutUsSection() {
  return (
    <Box sx={{ marginBottom: "3rem" }}>
      <Container>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", md: "50%" },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              paddingRight: { xs: 0, md: "1rem" },
            }}
          >
            <Typography
              variant="h2"
              sx={{
                fontSize: "clamp(1.8rem, 1.3654rem + 0.9065vw, 2rem)",
                fontWeight: "bold",
                textTransform: "uppercase",
                marginBottom: "1rem",
              }}
            >
              About us
            </Typography>
            <Typography>
              Ward Property Management delivers specialised services in
              residential property management and rentals. Our commitment and
              expertise through lease management, tenant screening and
              maintenance provides peace of mind whilst maximising your
              properties potential. Let us take care of your property and enjoy
              more of the free time you deserve. Your trusted property
              management partner.
            </Typography>
            {/*<Box
              sx={{
                marginTop: "1rem",
              }}
            >
              <Link to="/about">
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#0c121b", // Replace with your HTML color code
                    color: "#FFFFFF", // Text color
                    "&:hover": {
                      backgroundColor: "#2d394b", // Hover color
                    },
                  }}
                >
                  Meet the team
                </Button>
                </Link>
            </Box>*/}
          </Box>
          <Box
            sx={{
              width: { xs: "100%", md: "50%" },
              display: "flex",
              justifyContent: "center",
              paddingLeft: { md: 0, lg: "6rem" },
              paddingTop: { xs: "2rem", md: 0 },
            }}
          >
            <img
              src={AboutImg}
              alt=""
              style={{
                height: "auto",
                maxWidth: "100%",
                borderRadius: "25px",
              }}
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default AboutUsSection;
