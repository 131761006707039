import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; 
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied'; // Importing the sad face icon from Material UI

function NotFound() {
  const navigate = useNavigate(); 
  const [secondsLeft, setSecondsLeft] = useState(10); 
  const [fadeIn, setFadeIn] = useState(false); // Track fade-in effect

  useEffect(() => {
    // Set fadeIn to true after component mounts
    setTimeout(() => setFadeIn(true), 100);

    // Countdown logic
    const countdownInterval = setInterval(() => {
      setSecondsLeft((prev) => prev - 1); 
    }, 1000);

    const timer = setTimeout(() => {
      navigate("/"); 
    }, 3000);

    return () => {
      clearTimeout(timer);
      clearInterval(countdownInterval);
    };
  }, [navigate]);

  return (
    <>
      <main
        className={`grid min-h-full place-items-center bg-white mt-40 px-10 py-24 sm:py-32 lg:px-8 transition-opacity duration-1000 ease-in-out ${
          fadeIn ? 'opacity-100' : 'opacity-0'
        }`}
      >
        <div className="text-center">
          <p className="text-base font-semibold " style={{ color: "#0c121b" }}>404</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Page not found</h1>
          <p className="mt-6 text-base leading-7 text-gray-600">Sorry, we couldn’t find the page you’re looking for.</p>
        </div>
        
        {/* Redirect message positioned a little lower with the sad face icon and playful message */}
        <div className="mt-6 text-center">
          <SentimentDissatisfiedIcon style={{ fontSize: 50, color: "#0c121b" }} />
          <p className="mt-2 text-lg font-semibold" style={{ color: "#0c121b" }}>
            Oops! Looks like you're lost. Redirecting you home in {secondsLeft} seconds...
          </p>
        </div>
      </main>
    </>
  );
}

export default NotFound;
