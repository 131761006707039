import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import RentItem from "../components/RentSaleComponents/PropertyList";
import SearchIcon from "@mui/icons-material/Search"; // Import the magnifying glass icon
import {
  Container,
  Pagination,
  Autocomplete,
  Stack,
  Divider,
  Typography,
  Box,
  Slider,
  Collapse,
  Select,
  MenuItem,
  Grid,
  Alert,
  Snackbar,
  Button,
  FormControl,
  InputLabel,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import RentHeaderImg from "../assets/images/rent-header-img.jpg";
import iconW from "../assets/icons/2-t.png";
import APISplash from "../components/Misc/APISplash";
import FadeInUp from "../components/FadeInUp";
import "../components/Misc/SplashPage.css";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet-async";
import {
  FormatColorResetOutlined,
  SentimentDissatisfied,
} from "@mui/icons-material";
import SplashPage from "../components/Misc/SplashPage";

function Rent({ title, setValue }) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const handleScrollToTop = () => {
    if (topOfListRef.current) {
      topOfListRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [minBeds, setMinBeds] = useState(1);
  const [maxBeds, setMaxBeds] = useState(10); // Set a reasonable max value
  const [minBathrooms, setMinBathrooms] = useState(1);
  const [maxBathrooms, setMaxBathrooms] = useState(10); // Set a reasonable max value
  const [userCoords, setUserCoords] = useState(null);
  const [rentItems, setRentItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [distanceSet, setDistance] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOption, setSortOption] = useState("featured");
  const [searchQuery, setSearchQuery] = useState(""); // New state for search query
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const topOfListRef = useRef(null);
  const [towns, setTowns] = useState([]); // State to hold towns
  const [filteredRentItems, setFilteredRentItems] = useState([]);
  const [priceRange, setPriceRange] = useState([250, 2500]);
  const [distanceRange, setDistanceRange] = useState([0, 50]);
  const [sortedItems, setSortedItems] = useState([...rentItems]);

  const handleRangeChange = (event, newValue) => {
    setDistanceRange(newValue);
  };

  const handlePriceChange = (event, newValue) => {
    setPriceRange(newValue);
  };
  const handleBedsChange = (event, newValue) => {
    setMinBeds(newValue[0]);
    setMaxBeds(newValue[1]);
  };

  const handleBathroomsChange = (event, newValue) => {
    setMinBathrooms(newValue[0]);
    setMaxBathrooms(newValue[1]);
  };

  const resetFilters = (event) => {
    setPriceRange([250, 2500]);
    setDistanceRange([0, 50]);
    setMinBeds(1);
    setMaxBeds(10);
    setMaxBathrooms(10);
    setMinBathrooms(1);
    setSearchQuery("");
    setCurrentPage(1);
    setFiltersOpen(false);
    setSortOption("featured");
  };

  useEffect(() => {
    const applyFilters = () => {
      let filtered = rentItems;

      //Filter by distance
      if (distanceRange[0] !== 0) {
        filtered = filtered.filter(
          (item) => parseFloat(item.distance) >= distanceRange[0]
        );
      }
      if (distanceRange[1] !== 50) {
        filtered = filtered.filter(
          (item) => parseFloat(item.distance) <= distanceRange[1]
        );
      }

      // Filter by bed range
      if (minBeds) {
        filtered = filtered.filter((item) => item.bedrooms >= minBeds);
      }
      if (maxBeds) {
        filtered = filtered.filter((item) => item.bedrooms <= maxBeds);
      }

      // Filter by bathroom range
      if (minBathrooms) {
        filtered = filtered.filter((item) => item.bathrooms >= minBathrooms);
      }
      if (maxBathrooms) {
        filtered = filtered.filter((item) => item.bathrooms <= maxBathrooms);
      }

      if (priceRange[0] !== 0) {
        filtered = filtered.filter(
          (item) => parseFloat(item.price) >= priceRange[0]
        );
      }
      if (priceRange[1] !== 10000) {
        filtered = filtered.filter(
          (item) => parseFloat(item.price) <= priceRange[1]
        );
      }

      setFilteredRentItems(filtered);
    };

    applyFilters();
  }, [
    rentItems,
    minBeds,
    maxBeds,
    minBathrooms,
    maxBathrooms,
    priceRange,
    distanceRange,
  ]);

  const [open, setOpen] = useState(false);
  const [filterOpen, setFiltersOpen] = useState(false);
  const handleAlertOpen = () => {
    setOpen(true);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  // Get user coordinates
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserCoords({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          console.error("Error getting location", error);
        }
      );
    }
  }, []);

  const [randomProperty, setRandomProperty] = useState({
    image1: [], // Initialize with an empty array
  });

  // Fetch data from the API - stay
  useEffect(() => {
    setLoading(true); // Optional: Set loading state
    axios
      .get(`${apiUrl}/properties`)
      .then((response) => {
        // Filter out properties with status "Agreed"
        //const filteredProperties = response.data.filter(
        //  (property) => property.status !== "Agreed"
        //);

        const filteredProperties = response.data;

        // Store the filtered properties in state
        setRentItems(filteredProperties);

        // Select a random property from the filtered list
        setRandomProperty(
          filteredProperties[
            Math.floor(Math.random() * filteredProperties.length)
          ]
        );

        // Set unique towns from the filtered list
        setTowns([...new Set(filteredProperties.map((item) => item.town))]);

        setLoading(false);
      })
      .catch((error) => {
        console.error("There was an error fetching the data:", error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (userCoords) {
      // Assuming rentItems contains the fetched properties
      setSortedItems(
        rentItems.map((item) => {
          const distance = calculateDistance(
            userCoords.lat,
            userCoords.lng,
            item.mapCoords.lat,
            item.mapCoords.lng
          );

          return { ...item, distance }; // Add distance to each item
        })
      );
    } else {
      setSortedItems([...rentItems]);
    }
  }, [userCoords, rentItems]);

  const handleSortChange = (event) => {
    const value = event.target.value;
    setSortOption(value);

    const sanitizePrice = (price) => parseFloat(price.replace(/,/g, ""));

    // Sorting based on selected filter option
    switch (value) {
      case "priceLowToHigh":
        sortedItems.sort(
          (a, b) => sanitizePrice(a.price) - sanitizePrice(b.price)
        );
        break;
      case "priceHighToLow":
        sortedItems.sort(
          (a, b) => sanitizePrice(b.price) - sanitizePrice(a.price)
        );
        break;
      case "featured":
        sortedItems.sort((a, b) => b.views - a.views);
        break;
      case "bedsLowToHigh":
        sortedItems.sort((a, b) => a.bedrooms - b.bedrooms);
        break;
      case "bedsHighToLow":
        sortedItems.sort((a, b) => b.bedrooms - a.bedrooms);
        break;
      case "proximity":
        if (userCoords) {
          if (sortedItems.length === 1) {
            // Handle the case for a single item
            setDistance(true);
            sortedItems[0].distance = calculateDistance(
              userCoords.lat,
              userCoords.lng,
              sortedItems[0].mapCoords.lat,
              sortedItems[0].mapCoords.lng
            );
          } else {
            // Sort the items if there are multiple
            sortedItems.sort((a, b) => {
              setDistance(true);
              const distA = calculateDistance(
                userCoords.lat,
                userCoords.lng,
                a.mapCoords.lat,
                a.mapCoords.lng
              );
              const distB = calculateDistance(
                userCoords.lat,
                userCoords.lng,
                b.mapCoords.lat,
                b.mapCoords.lng
              );
              return distA - distB; // Sort in ascending order of distance
            });
          }
        } else {
          handleAlertOpen();
        }
        break;

      case "recentlyAdded":
        sortedItems.sort(
          (a, b) => new Date(b.dateAdded) - new Date(a.dateAdded)
        ); // Sort in descending order of the 'added' date
        break;
      default:
        break;
    }

    setRentItems(sortedItems);
    handlePageChange(null, 1);
  };

  function calculateDistance(lat1, lon1, lat2, lon2) {
    const toRad = (value) => (value * Math.PI) / 180;

    const R = 3959; // Radius of the Earth in kilometers
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) *
        Math.cos(toRad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c; // Distance in kilometers
    return distance;
  }

  // Handle pagination page change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    setTimeout(() => {
      if (topOfListRef.current) {
        topOfListRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };

  // Logic for displaying current items based on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // Filter the current items based on the search query
  const filteredItems = filteredRentItems.filter((item) => {
    const searchQueryLower = searchQuery.toLowerCase();

    // Combine all relevant fields into one string for loose matching
    const combinedFields =
      `${item.town} ${item.address} ${item.county}`.toLowerCase();

    // Split search query into words and check if any of the words match the combined fields
    return searchQueryLower
      .split(" ")
      .some((queryWord) => combinedFields.includes(queryWord));
  });

  // Get the current items for the current page
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  useEffect(() => {
    // Check if randomProperty is defined and has images
    if (
      !randomProperty ||
      !randomProperty.image1 ||
      randomProperty.image1.length === 0
    )
      return;

    const interval = setInterval(() => {
      setCurrentImageIndex(
        (prevIndex) => (prevIndex + 1) % randomProperty.image1.length
      );
      setValue(true);
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [randomProperty]); // Depend on randomProperty

  if (loading) {
    return (
      <div>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <SplashPage></SplashPage>
      </div>
    );
  }

  if (!randomProperty) {
    setValue(true);
  }

  return (
    <div>
      {randomProperty && (
        <Box
          sx={{
            position: "relative",
            height: "100vh",
            overflow: "hidden",
          }}
        >
          {randomProperty.image1.map((image, index) => (
            <Box
              key={index}
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                height: "100%",
                width: "100%",
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                opacity: currentImageIndex === index ? 1 : 0,
                transition: "opacity 1s ease-in-out",
                pointerEvents: "none", // Prevent interaction
              }}
            />
          ))}

          {/* Add your content here */}
          <Box
            sx={{
              position: "absolute",
              bottom: "0px", // Position it above the bottom of the box
              left: 0,
              width: "100%",
              backgroundColor: "rgba(222, 222, 232, 0.2)", // Grey background with 0.6 opacity
              display: "flex",
              justifyContent: "center", // Center text
              alignItems: "center",
            }}
          >
            <Link
              to={`/property/${randomProperty.urluuid}`}
              style={{ textDecoration: "none" }}
            >
              {randomProperty.status !== "Agreed" ? (
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "white",
                    textAlign: { xs: "center", md: "left" }, // Center text on mobile, align left on desktop
                  }}
                >
                  {randomProperty.address} available for viewings from{" "}
                  {randomProperty.viewableFrom}
                </Typography>
              ) : (
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "white",
                    textAlign: { xs: "center", md: "left" }, // Center text on mobile, align left on desktop
                  }}
                >
                  {randomProperty.address} {", "} {randomProperty.postcode} {", "} {randomProperty.town} {", "} {randomProperty.county} 
                </Typography>
              )}
            </Link>
          </Box>
          {/* This Box now displays on all screen sizes */}

          <Box
            display="flex" // Always display
            flexDirection={{ xs: "column", md: "row" }} // Stack items vertically on mobile, horizontally on desktop
            justifyContent="center"
            alignItems="center"
            sx={{ height: { md: "95vh", xs: "100vh" }, width: "100%" }} // Full width for better alignment
          >
            <Autocomplete
              freeSolo
              options={searchQuery.length >= 1 ? towns : []} // Only show options if input has 1 or more characters
              getOptionLabel={(option) => option}
              inputValue={searchQuery} // Track the input value
              onInputChange={(event, newValue) => {
                setSearchQuery(newValue || ""); // Update input value
              }}
              onChange={(event, newValue) => {
                setSearchQuery(newValue || ""); // Handle selection
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Search by town"
                  sx={{
                    textAlign: "center",
                    backgroundColor: "white",
                    minWidth: { xs: "350px", md: "400px" },
                    maxWidth: "100%",
                    marginBottom: { xs: 2, md: 0 },
                    borderRadius: "10px",
                    "& fieldset": {
                      borderRadius: "10px",
                    },
                  }}
                />
              )}
            />

            <Button
              variant="contained"
              onClick={handleScrollToTop}
              sx={{
                backgroundColor: "#0c121b",
                color: "#fff",
                height: "56px", // Set the button's height
                width: "56px", // Set the button's width to match the height
                borderRadius: "15px", // Rounded corners
                textTransform: "none",
                marginLeft: { xs: "3px" },
                marginTop: { xs: 2, md: 0 },
                marginBottom: { xs: 10, md: 0 }, // Add margin on mobile, none on desktop
                minWidth: 0, // Ensure the button doesn't stretch unnecessarily
                padding: 0, // Remove any padding to ensure icon is centered
                display: "flex",
                justifyContent: "center", // Center the icon horizontally
                alignItems: "center", // Center the icon vertically
                "&:hover": {
                  backgroundColor: "#2d394b",
                },
              }}
            >
              <SearchIcon />
            </Button>
          </Box>
        </Box>
      )}

      {randomProperty && (
        <Container ref={topOfListRef} sx={{ textAlign: "center", marginY: 2 }}>
          <Box>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Button
                  onClick={() => setFiltersOpen(!filterOpen)}
                  endIcon={filterOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  variant="contained"
                  sx={{
                    marginBottom: "16px",
                    backgroundColor: "#0c121b",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#283445",
                      color: "#fff",
                    },
                  }}
                >
                  {filterOpen ? "Hide Filters" : "Show Filters"}
                </Button>
              </Grid>

              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => resetFilters()}
                  sx={{
                    marginBottom: "16px",
                    backgroundColor: "#0c121b",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#283445",
                      color: "#fff",
                    },
                  }}
                >
                  Reset
                </Button>
              </Grid>

              <Grid item>
                <FormControl
                  variant="outlined"
                  sx={{ minWidth: 200, borderRadius: "20px" }}
                >
                  <InputLabel id="sort-label">Sort by</InputLabel>
                  <Select
                    labelId="sort-label"
                    value={sortOption}
                    onChange={handleSortChange}
                    label="Sort by"
                    sx={{
                      borderRadius: "20px", // Rounded corners for the dropdown
                      "& fieldset": {
                        borderRadius: "20px", // Ensures the Select's border is rounded
                      },
                    }}
                  >
                    <MenuItem value="featured">Most Popular</MenuItem>
                    <MenuItem value="priceLowToHigh">
                      Price: Low to High
                    </MenuItem>
                    <MenuItem value="priceHighToLow">
                      Price: High to Low
                    </MenuItem>
                    <MenuItem value="bedsLowToHigh">Beds: Low to High</MenuItem>
                    <MenuItem value="bedsHighToLow">Beds: High to Low</MenuItem>
                    {userCoords && (
                      <MenuItem value="proximity">Distance: Closest</MenuItem>
                    )}{" "}
                    <MenuItem value="recentlyAdded">Recently Added</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Collapse in={filterOpen}>
              <Box
                sx={{
                  backgroundColor: "#f0f0f0", // Light grey background
                  padding: "16px", // Padding around the inputs
                  borderRadius: "8px",
                  marginTop: "20px", // Rounded corners
                }}
              >
                <Grid container spacing={2}>
                  <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={5}>
                      <Box
                        sx={{
                          width: "100%",
                          margin: "16px 0",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          paddingRight: "5px",
                        }}
                      >
                        <Typography variant="h5" gutterBottom>
                          Beds
                        </Typography>
                        <Slider
                          value={[minBeds, maxBeds]}
                          onChange={handleBedsChange}
                          valueLabelDisplay="auto"
                          min={1}
                          max={10} // Set your max beds value
                          sx={{
                            color: "#0c121b",
                            "& .MuiSlider-thumb": {
                              backgroundColor: "#fff",
                              border: "2px solid #0c121b",
                            },
                            "& .MuiSlider-track": {
                              backgroundColor: "#0c121b",
                            },
                            "& .MuiSlider-rail": {
                              backgroundColor: "#0c121b",
                            },
                          }}
                        />
                        <Typography variant="body2" color="textSecondary">
                          Min: {minBeds}, Max: {maxBeds}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={5}>
                      <Box
                        sx={{
                          width: "100%",
                          margin: "16px 0",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          paddingLeft: "5px",
                        }}
                      >
                        <Typography variant="h5" gutterBottom>
                          Bathrooms
                        </Typography>
                        <Slider
                          value={[minBathrooms, maxBathrooms]}
                          onChange={handleBathroomsChange}
                          valueLabelDisplay="auto"
                          min={1}
                          max={10} // Set your max bathrooms value
                          sx={{
                            color: "#0c121b",
                            "& .MuiSlider-thumb": {
                              backgroundColor: "#fff",
                              border: "2px solid #0c121b",
                            },
                            "& .MuiSlider-track": {
                              backgroundColor: "#0c121b",
                            },
                            "& .MuiSlider-rail": {
                              backgroundColor: "#0c121b",
                            },
                          }}
                        />
                        <Typography variant="body2" color="textSecondary">
                          Min: {minBathrooms}, Max: {maxBathrooms}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Box sx={{ width: "100%", margin: "16px 0" }}>
                      <Typography gutterBottom variant="h5">
                        Price
                      </Typography>

                      <Slider
                        value={priceRange}
                        onChange={handlePriceChange}
                        valueLabelDisplay="auto"
                        min={250}
                        max={2500} // Set your max price value
                        step={100} // Set the step value
                        sx={{
                          color: "#0c121b",
                          "& .MuiSlider-thumb": {
                            backgroundColor: "#fff",
                            border: "2px solid #0c121b",
                          },
                          "& .MuiSlider-track": {
                            backgroundColor: "#0c121b",
                          },
                          "& .MuiSlider-rail": {
                            backgroundColor: "#0c121b",
                          },
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="space-between">
                      <span>Min Price: £{priceRange[0]} pcm</span>
                      <span>Max Price: £{priceRange[1]} pcm</span>
                    </Box>
                  </Grid>

                  {userCoords && ( // Only render this section if userCoords is available
                    <>
                      <Grid item xs={12}>
                        <Box sx={{ width: "100%", margin: "16px 0" }}>
                          <Typography gutterBottom variant="h5">
                            Distance
                          </Typography>

                          <Slider
                            value={distanceRange}
                            onChange={handleRangeChange}
                            valueLabelDisplay="auto"
                            min={0}
                            max={50}
                            step={5}
                            sx={{
                              color: "#0c121b",
                              "& .MuiSlider-thumb": {
                                backgroundColor: "#fff",
                                border: "2px solid #0c121b",
                              },
                              "& .MuiSlider-track": {
                                backgroundColor: "#0c121b",
                              },
                              "& .MuiSlider-rail": {
                                backgroundColor: "#0c121b",
                              },
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box display="flex" justifyContent="space-between">
                          <span>Min Distance: {distanceRange[0]} miles</span>
                          <span>
                            Max Distance: {distanceRange[1]}
                            {distanceRange[1] === 50 ? "+" : ""} miles
                          </span>
                        </Box>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Box>
            </Collapse>
          </Box>
          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleAlertClose}
          >
            <Alert onClose={handleAlertClose} severity="error">
              You must enable your location in order to sort by proximity.
            </Alert>
          </Snackbar>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: 2,
            }}
          >
            <Box sx={{ flex: 1 }} /> {/* Empty space on the left */}
            <Typography variant="h5" sx={{ textAlign: "center", flex: 1 }}>
              Current Listings
            </Typography>
            <Typography variant="body1" sx={{ textAlign: "right", flex: 1 }}>
              Results: {filteredItems.length}
            </Typography>
          </Box>
          <Divider />
        </Container>
      )}

      <FadeInUp>
        <Container sx={{ textAlign: "center" }}>
          {currentItems.length === 0 ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: 38,
                marginBottom: 25,
              }}
            >
              <SentimentDissatisfied sx={{ fontSize: 40, color: "grey.500" }} />
              <Typography
                variant="h5"
                sx={{ fontWeight: "normal", marginTop: 2 }}
              >
                Well this is awkward... we currently don't have any properties
                available, check back later for more!
              </Typography>
            </Box>
          ) : (
            currentItems.map((item) => (
              <RentItem
                key={item.id}
                id={item.id}
                title={item.title}
                address={item.address}
                price={item.price}
                shortDescription={item.longDescription}
                bedrooms={item.bedrooms}
                bathrooms={item.bathrooms}
                livingrooms={item.livingrooms}
                img={item.image1}
                urluuid={item.urluuid}
                mapCoords={item.mapCoords}
                distance={item.distance}
                dateAdded={item.dateAdded}
                distanceSet={distanceSet}
                postcode={item.postcode}
                town={item.town}
                status={item.status}
                county={item.county}
                viewableFrom={item.viewableFrom}
                views={item.views}
              />
            ))
          )}
        </Container>
      </FadeInUp>

      <Stack
        spacing={2}
        sx={{ margin: 4, justifyContent: "center", alignItems: "center" }}
      >
        <Pagination
          count={Math.ceil(filteredItems.length / itemsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          sx={{
            "& .MuiPaginationItem-root": {
              color: "#0c121b", // Color for unselected pagination items
            },
            "& .MuiPaginationItem-root.Mui-selected": {
              backgroundColor: "#0c121b", // Background color for selected pagination item
              color: "#FFFFFF", // Text color for selected pagination item
            },
            "& .MuiPaginationItem-root:hover": {
              backgroundColor: "#2d394b", // Hover background color for pagination items
              color: "#FFFFFF", // Hover text color for pagination items
            },
          }}
        />

        <Box display="flex" justifyContent="flex-end" gap={2} mb={2}>
          Items per Page
          {[5, 10, 25, "All"].map((count) => (
            <Typography
              key={count}
              variant="body1"
              sx={{
                cursor: "pointer",
                color:
                  itemsPerPage === (count === "All" ? 1000 : count)
                    ? "#0c121b"
                    : "text.primary", // Updated color
                fontWeight:
                  itemsPerPage === (count === "All" ? 1000 : count)
                    ? "bold"
                    : "normal",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
              onClick={() => setItemsPerPage(count === "All" ? 1000 : count)}
            >
              {count}
            </Typography>
          ))}
        </Box>
      </Stack>

      <Footer />
    </div>
  );
}

export default Rent;
