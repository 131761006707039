import React from "react";
import {
  Container,
  Box,
  Typography,
  Link,
  Grid,
  IconButton,
  ListItemIcon,
  Divider,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import ApartmentIcon from "@mui/icons-material/Cottage";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import HelpIcon from "@mui/icons-material/SettingsSuggest";
import Emoji from "@mui/icons-material/EmojiPeople";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import MailOutlineIcon from '@mui/icons-material/MailOutline';


// Import the image you want to use
import logoImage from "../assets/icons/buildings.png"; // Update the path accordingly
import tdsLogo from "../assets/icons/tdsni-logo.png"; // Update the path accordingly

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#0c121b",
        color: "#ffffff",
        py: 4,
        position: "relative",
        minHeight: "200px", // Ensure there's enough space for positioning
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Quick Links
            </Typography>
            <Divider sx={{ bgcolor: "#202c3d" }} />

            <Box display="flex" alignItems="center" sx={{ my: 1 }}>
              <ListItemIcon sx={{ minWidth: "unset", mr: 1 }}>
                <HomeIcon fontSize="small" sx={{ color: "#ffffff" }} />
              </ListItemIcon>
              <Link href="/" color="inherit" underline="none">
                Home
              </Link>
            </Box>
            <Box display="flex" alignItems="center" sx={{ my: 1 }}>
              <ListItemIcon sx={{ minWidth: "unset", mr: 1 }}>
                <ApartmentIcon fontSize="small" sx={{ color: "#ffffff" }} />
              </ListItemIcon>
              <Link href="/#/properties" color="inherit" underline="none">
                Properties
              </Link>
            </Box>
            <Box display="flex" alignItems="center" sx={{ my: 1 }}>
              <ListItemIcon sx={{ minWidth: "unset", mr: 1 }}>
                <ContactMailIcon fontSize="small" sx={{ color: "#ffffff" }} />
              </ListItemIcon>
              <Link href="/#/contact" color="inherit" underline="none">
                Contact
              </Link>
            </Box>
            <Box display="flex" alignItems="center" sx={{ my: 1 }}>
              <ListItemIcon sx={{ minWidth: "unset", mr: 1 }}>
                <HelpIcon fontSize="small" sx={{ color: "#ffffff" }} />
              </ListItemIcon>
              <Link href="/#/services/property-management" color="inherit" underline="none">
                Services
              </Link>
            </Box>
            <Box display="flex" alignItems="center" sx={{ my: 1 }}>
              <ListItemIcon sx={{ minWidth: "unset", mr: 1 }}>
                <Emoji fontSize="small" sx={{ color: "#ffffff" }} />
              </ListItemIcon>
              <Link href="/#/landlords" color="inherit" underline="none">
                Landlords
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" textAlign="center" gutterBottom>
              Contact Us
            </Typography>
            <Divider sx={{ bgcolor: "#202c3d" }} />

            <Typography variant="body2" textAlign="center"gutterBottom>
              98 High Street, Bangor, BT20 5DH, Northern Ireland
            </Typography>
            <Typography variant="body2" textAlign="center">
      <Link href="mailto:zak@wardpropertymanagement.co.uk" style={{ textDecoration: "none", color: "inherit" }}>
        <MailOutlineIcon sx={{ verticalAlign: "middle", marginRight: 1 }} />
        Email Us
      </Link>
    </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                pt: 2,
              }}
            >
              <img
                src={logoImage}
                alt="Logo"
                style={{ maxWidth: "200px", marginTop: "20px" }} // Adjust size as needed
              />
            </Box>
            {/*<Typography variant="body2">
              Phone: 07511033858
  </Typography>*/}
          </Grid>
          <Grid item xs={12} sm={4}               textAlign="center">
            <Typography variant="h6" textAlign="center" gutterBottom>
              Follow Us
            </Typography>
            <Divider sx={{ bgcolor: "#202c3d" }} />

            <IconButton
              href="https://www.facebook.com/profile.php?id=61569323850098"
              color="inherit"
              sx={{ mx: 1 }}
            >
              <FacebookIcon fontSize="medium" />
            </IconButton>
            <IconButton
              href="https://instagram.com/wardpropertymanagement"
              color="inherit"
              sx={{ mx: 1 }}
            >
              <InstagramIcon fontSize="medium" />
            </IconButton>
            <Box sx={{ alignItems: "left", pt: 2 }}>
              <img
                src={tdsLogo}
                alt="Logo"
                style={{ maxWidth: "75px", marginTop: "0px" }} // Adjust size as needed
              />
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Box sx={{ textAlign: "center", pt: 2, marginBottom: "-10px" }}>
        <Typography variant="body2">
          © {new Date().getFullYear()} Ward Property Management Ltd. All rights
          reserved.
        </Typography>
      </Box>

      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          right: 0,
          p: 1,
          textAlign: "center",
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        <Typography fontSize="8px" color="#46566b">
          designed, developed and deployed by ryan johnston.
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
