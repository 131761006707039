import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { Box } from "@mui/system";
import PropertyIcons from "../Misc/PropertyIcons";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import ReadMore from "@mui/icons-material/ReadMore";
import CloseFullscreen from "@mui/icons-material/CloseFullscreen";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CardItem(props) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenInNewWindow = () => {
    const baseUrl = window.location.origin;
    const fullUrl = `${baseUrl}/#/property/${props.url}`;
    window.open(fullUrl, "_blank");
  };

  return (
    <>
      <Box sx={{ position: "relative", display: "inline-block" }}>
        {/* Card with hover effect */}
        <Card
          sx={{
            width: 400,
            marginRight: "1rem",
            marginLeft: "1rem",
            marginBottom: "1rem",
            transition: "transform 0.3s ease-in-out",
            "&:hover": {
              transform: "scale(1.05)",
            },
          }}
        >
          <CardActionArea onClick={handleOpenInNewWindow}>
            <CardMedia
              component="img"
              image={props.image}
              alt="property image"
              sx={{ maxHeight: "250px" }}
            />
            <CardContent>
              <Typography
                gutterBottom
                variant="body1"
                component="div"
                sx={{
                  textTransform: "capitalize",
                  textAlign: "center",
                }}
              >
                {props.title}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  textTransform: "uppercase",
                  textAlign: "center",
                }}
              >
                {props.address}, {props.town}
              </Typography>
              <Typography
                variant="h5"
                color="#0c121b"
                sx={{
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                £{Math.round(props.price)} pcm
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "1rem",
                }}
              >
                <PropertyIcons
                  bedrooms={props.itemBedrooms}
                  bathrooms={props.itemBathrooms}
                  livingrooms={props.itemLivingrooms}
                />
              </Box>
            </CardContent>
            {props.status && (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  width: 0,
                  height: 0,
                  borderLeft: "100px solid transparent",
                  borderTop: "100px solid #0c121b", // Banner background color
                  zIndex: 1,
                  opacity: 0.8,
                }}
              >
                <Typography
                  sx={{
                    position: "absolute",
                    top: "-70px", // Adjust this for vertical positioning
                    right: "-35px", // Adjust this for horizontal positioning
                    width: "150px", // Adjust to fit text width
                    transform: "rotate(45deg)", // Rotate text to match the diagonal banner
                    color: "#FFFFFF",
                    fontSize: "12px",
                    fontWeight: "bold",
                    textAlign: "center",
                    opacity: 1,
                  }}
                >
                  {props.status}
                </Typography>
              </Box>
            )}
          </CardActionArea>
        </Card>

        {/* "Coming Soon" banner */}
      </Box>

      {/* MODAL */}
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="md"
        fullWidth
        sx={{
          borderRadius: "12px",
        }}
      >
        <Box
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            height: {
              xs: "85vh",
              sm: "85vh",
            },
            overflow: "hidden",
          }}
        >
          <CardMedia
            component="img"
            image={props.image}
            alt="property image"
            sx={{
              width: "100%",
              height: {
                xs: "45vh",
                sm: "52.5vh",
              },
              objectFit: "cover",
            }}
          />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              padding: {
                xs: "0.8rem",
                sm: "1rem",
              },
              flexGrow: 1,
              overflowY: "auto",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontSize: {
                  xs: "1.3em",
                  sm: "1.5em",
                },
                fontWeight: "bold",
                marginBottom: "1rem",
              }}
            >
              {props.address}
            </Typography>
            <Typography variant="h6" sx={{ mb: 2 }}>
              {props.title}
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              {props.description}
            </Typography>
            <PropertyIcons
              sx={{ mb: 2 }}
              bedrooms={props.itemBedrooms}
              bathrooms={props.itemBathrooms}
              livingrooms={props.itemLivingrooms}
              align="center"
            />
            <Divider sx={{ mb: 2 }} />
            <Typography variant="h5" sx={{ mb: 2 }}>
              Price: £{Math.round(props.price)} pcm
            </Typography>

            {/* Buttons */}
            <Box
              sx={{
                display: "flex",
                gap: 2,
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Button
                variant="contained"
                onClick={handleOpenInNewWindow}
                sx={{
                  backgroundColor: "#0c121b",
                  color: "#FFFFFF",
                  "&:hover": {
                    backgroundColor: "#2d394b",
                  },
                  fontSize: {
                    xs: "0.8rem",
                    sm: "1rem",
                  },
                }}
                startIcon={<ReadMore />}
              >
                View Listing
              </Button>
              <Button
                variant="contained"
                onClick={handleClose}
                sx={{
                  backgroundColor: "#0c121b",
                  color: "#FFFFFF",
                  "&:hover": {
                    backgroundColor: "#2d394b",
                  },
                  fontSize: {
                    xs: "0.8rem",
                    sm: "1rem",
                  },
                }}
                startIcon={<CloseFullscreen />}
              >
                Close
              </Button>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}

export default CardItem;
