// src/SplashPage.js
import React from "react";
import { PropagateLoader } from "react-spinners";

const SplashPage = () => {
  return (

      <PropagateLoader color="#0c121b" size="10px" />
  );
};

export default SplashPage;
