import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Container } from "@mui/material";
import RentSaleProperty from "../components/RentSaleComponents/MainListingItem";
import APISplash from "../components/Misc/APISplash";
import { Helmet } from 'react-helmet-async';
import Footer from "../components/Footer";
import "../components/Misc/SplashPage.css";

function RentProperty() {
  const { id } = useParams();
  const [rentProperty, setRentProperty] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;


  useEffect(() => {
    axios
      .get(
        `${apiUrl}/property?urluuid=${id}`
      ) // Replace with your API URL
      .then((response) => {
        const property = response.data;
        setRentProperty(property);
      })
      .catch((error) => {
        console.error("There was an error fetching the property data:", error);
      });
  }, [id]);

  return (
    <div>
      <Helmet>
      <title>{rentProperty ? rentProperty.address : 'Loading Property...'}</title>
      </Helmet>
      {rentProperty ? (
        <RentSaleProperty
          propertyAddress={rentProperty.address}
          propertyImage={rentProperty.image1}
          propertyImages={rentProperty.images}
          propertyPrice={rentProperty.price}
          propertyLongDescription={rentProperty.longDescription}
          propertyBedrooms={rentProperty.bedrooms}
          propertyBathrooms={rentProperty.bathrooms}
          propertyLivingrooms={rentProperty.livingrooms}
          propertyMapCoords={rentProperty.mapCoords}
          features={rentProperty.features}
          mainDescription={rentProperty.mainDescription}
          status={rentProperty.status}
          style={rentProperty.style}
          viewableFrom={rentProperty.viewableFrom}
          leaseLength={rentProperty.leaseLength}
          deposit={rentProperty.deposit}
          energyRating={rentProperty.energyRating}
          heatingType={rentProperty.heatingType}
          furnishing={rentProperty.furnishing}
          floorPlan={rentProperty.floorPlan}
          floorPlan2={rentProperty.floorPlan2}
          town={rentProperty.town}
          postcode={rentProperty.postcode}
          county={rentProperty.county}
          up={rentProperty.broadbandMaxUp}
          down={rentProperty.broadbandMaxDown}
          views={rentProperty.views}
        />
      ) : (
        <div >
          <Container
            sx={{ position: "relative", width: "100%", height: "100vh" }}
          >
            {" "}
            {/* Ensure the container has full height */}
            <div className="load-page2">
              {" "}
              <APISplash></APISplash>
            </div>
          </Container>
          
        </div>
      )}
      <Footer></Footer>
    </div>
  );
}

export default RentProperty;
