import React from "react";
import { Box } from "@mui/material";
import discoverImg from "../assets/images/help.jpeg";
import ManageServiceCardChild from "./MaintenancePageComponents/ManageServiceCardChild";

function ServiceTicketCard() {
  return (
    <Box
      sx={{
        height: "75vh",
        backgroundImage: `url(${discoverImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
      }}
    >
      <ManageServiceCardChild
        variant="h5"
        name="No one likes issues"
        description="Thats why we're here to help, please submit a request and we'll reach out to assist."
      />
    </Box>
  );
}

export default ServiceTicketCard;
