import React from 'react';
import { Paper} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { keyframes } from '@emotion/react'; // For animation

// Define fade-in animation
const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

function Item(props) {
    const theme = createTheme({
        typography: {
            h1: {
                fontWeight: 700,
                color: '#ffffff', 
                fontSize: '4rem',
                textShadow: '2px 2px 8px rgba(0, 0, 0, 0.5)', // Adds subtle shadow
                fontFamily: 'Montserrat, sans-serif', // Modern font choice
                animation: `${fadeIn} 1.5s ease-in-out`, // Fade-in animation
                '@media (max-width:600px)': {
                    fontSize: '2.5rem',
                },
                '@media (max-width:900px)': {
                    fontSize: '3rem',
                },
            },
            h5: {
                fontWeight: 300,
                color: '#ffffff',
                fontSize: '1.2rem',
                textShadow: '1px 1px 5px rgba(0, 0, 0, 0.5)', // Adds shadow to tagline
                fontFamily: 'Playfair Display, serif', // A serif font for elegance
                animation: `${fadeIn} 2s ease-in-out`, // Slightly delayed animation
                '@media (max-width:600px)': {
                    fontSize: '1rem',
                },
                '@media (max-width:900px)': {
                    fontSize: '1.1rem',
                },
            },
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <Paper 
                sx={{
                    backgroundImage: { 
                        xs: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${props.item.img})`, 
                        md: `linear-gradient(${props.item.gradient}), url(${props.item.img})` 
                    },
                    backgroundSize: 'cover', 
                    backgroundPosition: { xs: `${props.item.bgPosition}`, sm: 'center' }, 
                    backgroundAttachment: { xs: 'unset', md: 'fixed' },
                    height: '100vh',
                    borderRadius: 0,
                    display: 'flex', 
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    position: 'relative',
                }}
            >
                
            </Paper>
        </ThemeProvider>
    );
}

export default Item;
