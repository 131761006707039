import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Fade from '@mui/material/Fade'; // Import Fade component
import AccountTabs from './AccountTabs';

function App() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ text: '', type: '' });
  const [showMessage, setShowMessage] = useState(false); // Control visibility of the banner
  const apiUrl = process.env.REACT_APP_API_URL;

  const fetchUser = async () => {
    try {
      const response = await fetch(`${apiUrl}/user`, {
        method: 'GET',
        credentials: 'include',
      });

      const text = await response.text();
      if (response.ok) {
        const userData = JSON.parse(text);
        setUser(userData);
      } else {
        setUser(null);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      setUser(null);
    }
  };

  const login = async () => {
    setLoading(true);
    setMessage({ text: '', type: '' });
    setShowMessage(false); // Reset visibility
    try {
      const res = await axios.post(`${apiUrl}/login`, { username, password }, { withCredentials: true });
      localStorage.setItem('token', res.data.token);
      setMessage({ text: 'Login successful!', type: 'success' });
      fetchUser();
    } catch (error) {
      setMessage({ text: 'Login failed. Please check your credentials.', type: 'error' });
    } finally {
      setLoading(false);
      setShowMessage(true); // Show the message banner
      setTimeout(() => setShowMessage(false), 5000); // Hide it after 5 seconds
    }
  };

  const logout = async () => {
    setLoading(true);
    try {
      await axios.post(`${apiUrl}/logout`, {}, { withCredentials: true });
      localStorage.removeItem('token');
      setUser(null);
      setMessage({ text: 'Logged out successfully.', type: 'success' });
    } catch (error) {
      setMessage({ text: 'Failed to logout. Please try again.', type: 'error' });
    } finally {
      setLoading(false);
      setShowMessage(true);
      setTimeout(() => setShowMessage(false), 5000);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      fetchUser();
    }
  }, []);

  return (
    <div style={{ textAlign: 'center', padding: '20px', position: 'relative' }}>
      {/* Message Banner */}
      <Fade in={showMessage} timeout={500}>
        <Box
          sx={{
            position: 'fixed',
            bottom: '20px',
            left: '20px',
            zIndex: 1000,
            minWidth: '300px',
          }}
        >
          {message.text && (
            <Alert severity={message.type} sx={{ boxShadow: 1 }}>
              {message.text}
            </Alert>
          )}
        </Box>
      </Fade>

      {user ? (
        <>
                    <Button
            onClick={logout}
            variant="contained"
            sx={{
              backgroundColor: '#0c121b',
              color: '#FFFFFF',
              '&:hover': {
                backgroundColor: '#2d394b',
              },
              margin: '10px',
              marginTop: 10,
            }}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : 'Logout'}
          </Button>
          <AccountTabs />
        </>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            marginTop: 40,
          }}
        >
          <Box
            sx={{
              display: 'inline-block',
              padding: '20px',
              boxShadow: 4,
              borderRadius: '10px',
              border: '0.5px solid #cdcdcd',
            }}
          >
            <TextField
              label="Username"
              variant="outlined"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              sx={{
                marginBottom: '20px',
                width: '100%',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'grey',
                  },
                  '&:hover fieldset': {
                    borderColor: 'darkgrey',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'black',
                  },
                },
              }}
            />
            <TextField
              label="Password"
              variant="outlined"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              sx={{
                marginBottom: '20px',
                width: '100%',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'grey',
                  },
                  '&:hover fieldset': {
                    borderColor: 'darkgrey',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'black',
                  },
                },
              }}
            />
            <Button
              onClick={login}
              variant="contained"
              sx={{
                backgroundColor: '#0c121b',
                color: '#FFFFFF',
                '&:hover': {
                  backgroundColor: '#2d394b',
                },
                margin: '10px',
                width: '100%',
              }}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : 'Login'}
            </Button>
          </Box>
        </Box>
      )}
    </div>
  );
}

export default App;
