import React from "react";
import { Box, Typography, Tooltip } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import StarBorderPurple500 from "@mui/icons-material/StarBorderPurple500";
import StarIcon from "@mui/icons-material/Star";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const features = [
    { name: "Tenant Find", info: "We help find suitable tenants for your property." },
    { name: "Valuation", info: "Get a professional valuation of your property." },
    { name: "Property Advertisement", info: "Advertise your property to potential tenants." },
    { name: "Marketing of Property", info: "Comprehensive marketing strategy for your property." },
    { name: "Property Assessment", info: "Assessment of property condition and value." },
    { name: "Inventory Checks", info: "Detailed inventory checks before tenancy." },
    { name: "Legal agreement(s)", info: "Prepare and manage legal agreements." },
    { name: "Protected Deposit in TDS", info: "Secure deposit protection in a government scheme." },
    { name: "Photo/Videography Package", info: "High-quality photos and videos of your property." },
    { name: "Service Request System", info: "System for tenants to request maintenance." },
    { name: "Priority Support", info: "Get priority support for any issues." },
    { name: "Quarterly Inspections", info: "Regular inspections of the property every quarter." },
    { name: "Background Checks and Referencing", info: "We will ensure the tenants found have a right to rent and appropriate guarantors in place." },
];

const packages = [
  {
    name: "Tenant Find",
    features: [
      "Tenant Find",
      "Valuation",
      "Property Advertisement",
      "Marketing of Property",
    ],
    icon: <StarBorderPurple500 sx={{ color: "#C0C0C0" }} />,
  },
  {
    name: "Fully Managed",
    features: [
      "Tenant Find",
      "Valuation",
      "Property Assessment",
      "Inventory Checks",
      "Legal agreement(s)",
      "Protected Deposit in TDS",
      "Property Advertisement",
      "Marketing of Property",
      "Photo/Videography Package",
      "Service Request System",
      "Email Marketing Tools",
      "Priority Support",
      "Quarterly Inspections",
      "Background Checks and Referencing"
    ],
    icon: <StarIcon sx={{ color: "#FFD700" }}/>,
  },
];

const PackageTable = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          maxWidth: "1200px",
          transform: { xs: "scale(0.9)", sm: "scale(1)" }, // Scale down on mobile view
          transformOrigin: "top center", // Set transform origin for scaling
          overflow: "hidden", // Prevent overflow
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: `repeat(${packages.length + 1}, 1fr)`,
            width: "100%",
            textAlign: "center",
          }}
        >
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold", borderBottom: "1px solid #ddd" }}
          />
          {packages.map((pkg) => (
            <Box
              key={pkg.name}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                borderBottom: "1px solid #ddd",
                padding: "8px",
                fontWeight: "bold",
              }}
            >
              {pkg.icon}
              <Typography variant="h5">{pkg.name}</Typography>
            </Box>
          ))}
          {features.map((feature, index) => (
            <React.Fragment key={feature.name}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  borderBottom: "1px solid #ddd",
                  padding: "8px",
                  fontWeight: "bold",
                  backgroundColor: index % 2 === 0 ? "#f9f9f9" : "white",
                }}
              >
                <Typography>{feature.name}</Typography>
                <div className="sm:hidden xs:hidden">
                <Tooltip title={feature.info} arrow>
                  <HelpOutlineIcon fontSize="small" sx={{ marginLeft: "5px", color: "#0c121b", cursor: "pointer" }} />
                </Tooltip>
                </div>
              </Box>
              {packages.map((pkg, pkgIndex) => (
                <Box
                  key={pkg.name}
                  sx={{
                    borderBottom: "1px solid #ddd",
                    padding: "8px",
                    backgroundColor: index % 2 === 0 ? "#f9f9f9" : "white",
                  }}
                >
                  {pkg.features.includes(feature.name) ? (
                    <CheckIcon sx={{ color: "#0c121b" }} />
                  ) : (
                    <CloseIcon sx={{ color: "#d3d3d3" }} />
                  )}
                </Box>
              ))}
            </React.Fragment>
          ))}
        </Box>
      </Box>
    );
  };
  
  export default PackageTable;
