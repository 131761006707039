import { Box, Container } from '@mui/material';
import React from 'react';
import KeyContactCard from './KeyContactCard';
import Person1Img from '../assets/images/person1.jpeg';
import Person2Img from '../assets/images/person2.jpg';

function KeyContactSection() {
  return (
    <Box>
      <Container sx={{ 
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        justifyContent: { xs: 'space-around', lg: 'space-between' },
        alignItems: 'center'
    }}>
        <KeyContactCard img={Person1Img} name='Zak Bradley' title='Rentals' tel='0900800700' email='zak@wardpropertymanagement.co.uk' linked='https://www.linkedin.com/in/zak-bradley-05ba10177/'/>
        <KeyContactCard img={Person2Img} name='Ryan Johnston' title='Management' tel='0900800700' email='ryam@wardpropertymanagement.co.uk' linked='https://www.linkedin.com/in/ryanjohnstonn/' />
      </Container>
    </Box>
  )
}

export default KeyContactSection;