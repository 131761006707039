import React, { useState, useEffect } from 'react';
import { Box, Container } from '@mui/material';
import HeaderCarousel from '../components/HeaderCarousel';
import AboutUsSection from '../components/HomePageComponents/AboutUsSection';
import reviewImg from '../assets/images/review-img.jpg';
import KeyContactSection from '../components/KeyContactSection';
import WhyChooseUs from '../components/WhyChooseUsComponents/WhyChooseUs';
import ReviewsSection from '../components/ReviewsComponents/ReviewsSection';
import ContactCardsSection from '../components/ContactPageComponents/ContactCardsSection';
import { grey } from '@mui/material/colors';
import DiscoverSection from '../components/DiscoverSection';
import Footer from '../components/Footer';
import MultiItemsCarousel from '../components/MultiItemsCarousel/MultiItemsCarousel';
import FadeInUp from '../components/FadeInUp'; // Import the FadeInUp component
import { Helmet } from 'react-helmet-async';
import {SwipeCarousel} from '../components/Misc/modernCarousel';


function Home({title}) {

  const [cardsItems, setCardsItems] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;


  useEffect(() => {
    // Fetch data from the API
    fetch(`${apiUrl}/latest`)
      .then(response => response.json())
      .then(data => setCardsItems(data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);
return (
  <div>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    <Box sx={{ backgroundColor: grey[100], textAlign: {xs: "center", md: "left"}}}>
      <HeaderCarousel />
    </Box>
    <Box sx={{ backgroundColor: grey[100], paddingY: '3rem' }}>
      <FadeInUp>
        <AboutUsSection />
      </FadeInUp>
    </Box>
    {cardsItems.length > 0 && (
      <FadeInUp>
        <MultiItemsCarousel cardsItems={cardsItems} />
      </FadeInUp>
    )}
    <FadeInUp>
      <DiscoverSection />
    </FadeInUp>
    <FadeInUp>
      <WhyChooseUs />
    </FadeInUp>
    <Box
      sx={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url(${reviewImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}
    >
      {/*
      <Container>
        <FadeInUp>
          <ReviewsSection />
        </FadeInUp>
      </Container>
    */}
    </Box>
    <Box sx={{ backgroundColor: grey[100], paddingY: '3rem' }}>
      <FadeInUp>
        <ContactCardsSection />
      </FadeInUp>
            </Box>
    <Footer></Footer>
  </div>
);
}

export default Home;
